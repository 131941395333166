.containerStep3Check {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.containerStep3Check .title {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    text-align: center;
    color: #102649;
    margin-bottom: 27px;
}


.containerStep3Check .cardContent {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 652px;
    padding: 37px 34px 37px 34px;
    gap: 34px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px #CDDCE433;
    font-family: Outfit;
    font-size: 19px;
    font-weight: 400;
    line-height: 23.94px;
    text-align: left;
    color: #102649;

}

.containerStep3Check .cardContent .containerInputPhone {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.containerStep3Check .cardContent .containerInputPhone div {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: left;
    color: #8291A9;

}


.containerStep3Check .cardContent .input {
    width: 100%;
    height: 60px;
    top: 36px;
    gap: 0px;
    border-radius: 20px;
    background: #F3F5F8;
    border: none;
    cursor: pointer;
    padding: 0px 20px;
}

.containerStep3Check .cardContent button {
    width: 100%;
    height: 56px;
    border-radius: 16px;
    background: #77E191;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border: none;
    cursor: pointer;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    color: #102649;
}


.containerStep3Check .cardContent .buttonBackCheck {
    width: 100%;
    height: 56px;
    border-radius: 16px;
    background: #ffffff !important;
    border: 2px solid #77E191 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    color: #102649;
}

.containerStep3Check .cardContent button img {
    width: 25.94px;
    height: 22.75px;
}
.containerLoadingStep3  {
   display: flex;
   align-items: center;
   justify-content: center;
}

.containerLoadingStep3  .loadingDescription {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
 }
 

/* Animação de giro para o ícone */
.containerLoadingStep3 .loadingDescription img {
    width: 90px;
    height: 90px;
    /* animation: pulses 2s linear infinite; */
    animation: pulse 2s ease-in-out infinite;
}

/* Animação para o texto pulsar */
.containerLoadingStep3 .loadingDescription div {
    margin-top: 10px;
    animation: pulse 2s ease-in-out infinite;
}

/* Keyframes para o giro do ícone */
@keyframes pulses {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.75);
    }
    100% {
        transform: scale(1);
    }
}

/* Keyframes para o efeito de pulsar do texto */
@keyframes pulse {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

/* Keyframes para o fadeIn do contêiner */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 573px) {
    .containerStep3Check .title {
        font-size: 21px;
        line-height: 25.28px;
        margin-bottom: 15px;
    }
    
    
    .containerStep3Check .cardContent {
        width: 80vw;
        height: 100%;
        padding: 25px 22px 25px 22px;
        gap: 25px;
        font-size: 17px;
    }
    
    
    
    .containerStep3Check .cardContent .input {
        height: 40px;
    }
    
    .containerStep3Check .cardContent button {
        height: 40px;
        font-size: 16px;
    }
    
    
    .containerStep3Check .cardContent .buttonBackCheck {
        height: 40px;
        font-size: 16px;
    }
    
    .containerStep3Check .cardContent button img {
        width: 20px;
        height: 17px;
    }

}