@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAttendantSales {
    padding: 30px;
    font-family: "Outfit", sans-serif;
    color: #102649;
    /* -ms-overflow-style: none;
  scrollbar-width: none; */
}

.containerAttendantSales::-webkit-scrollbar {
    /* display: none; */
  }


.containerInfoSimulator {
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 20px 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    color: red;
}

.containerInfoSimulator strong {
    font-size: 18px;
}

.containerAttendantSales .titlePage {
    display: none;
}

.containerAttendantSales .contentMobile {
    display: none;
}

.containerAttendantSales .containerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.containerAttendantSales .containerMenu .menu {
    flex: 1;
    text-align: center;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.containerAttendantSales .disabledMenus {
    color: rgb(216, 213, 213) !important;
}


.containerAttendantSales .containerMenu .menuSelected {
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
}

.containerAttendantSales .containerMenu .line {
    width: 2px;
    height: 23px;
    background: #CDD3DD;
}

.containerAttendantSales .containerMenu .none {
    background-color: transparent !important;
}


.containerAttendantSales .containerInput {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.containerAttendantSales .containerInput .title {
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 700;
}

.containerAttendantSales .containerTitleDIv .title {
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 700;
}

.containerAttendantSales .containerTitleDIv .line {
    border: 1px solid #102649;
    padding: 0px 0px 0px 35px;
}

.containerAttendantSales .containerTitleDIv .sliderCOntainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.containerAttendantSales .cardChatHistory {
    background-color: #FFFFFF;
    padding: 35px;
    border-radius: 20px;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
    margin-bottom: 20px;
    word-break: break-all;
}

.containerAttendantSales .cardChatHistory .title {
    font-weight: 700;
    font-size: 28px;
    align-self:flex-start;
    margin-bottom: 15px;
    /* margin-top: 60px; */
}

.containerAttendantSales .cardChatHistory .containerImg {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerAttendantSales .containerInput .subTitleInput {
    font-size: 16px;
    font-weight: 400;
    color: #8291A9;
    margin-bottom: 10px;
}

.containerAttendantSales .containerInput .inputStyle {
    width: 100%;
    height: 60px;
    border-radius: 20px;
    background-color: #F3F5F8;
    border: none;
    padding: 10px;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: "Outfit", sans-serif;
    color: #102649;
}

.containerAttendantSales .containerButtons {
    display: flex;
    gap: 20px;
    align-self: flex-end;
}

.containerAttendantSales .containerButtons .buttonSimulator,
.containerAttendantSales .containerButtons .buttonSave,
.containerAttendantSales .containerButtons .buttonReset {
    font-size: 21px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    gap: 10px;
    cursor: pointer;
    width: 240px;
    height: 56px;
}

.containerAttendantSales .containerButtons .buttonSimulator {
    border: 2px solid #77E191;
    background-color: #FFFFFF;
}

.containerAttendantSales .containerButtons .buttonSave {
    background-color: #77E191;
}

.containerAttendantSales .containerButtons .buttonReset {
    background-color: #CE3C3C;
    color: #FFFFFF;
}

@media only screen and (max-width: 1500px) {
    .containerAttendantSales {
        padding: 20px;
    }

    .containerAttendantSales .cardChatHistory {
        font-size: 16px;
    }

    .containerAttendantSales .cardChatHistory .title {
        font-size: 18px;
    }


    .containerAttendantSales .containerMenu .menu {
        font-size: 16px;
    }

    /*     
    .containerAttendantSales .containerMenu .line {
        width: 2px;
        height: 23px;
        background: #CDD3DD;
    } */


    .containerAttendantSales .containerInput .title {
        font-size: 19px;
    }

    .containerAttendantSales .containerInput .subTitleInput {
        font-size: 14px;
    }

    .containerAttendantSales .containerInput .inputStyle {
        height: 50px;
        font-size: 19px;
    }


    .containerAttendantSales .containerButtons .buttonSimulator,
    .containerAttendantSales .containerButtons .buttonSave,
    .containerAttendantSales .containerButtons .buttonReset {
        font-size: 19px;
        width: 200px;
        height: 46px;
    }

}

@media only screen and (max-width: 1431px) {

    .containerAttendantSales .containerMenu .menu {
        font-size: 14px;
    }


    .containerAttendantSales .containerInput .title {
        font-size: 17px;
    }

    .containerAttendantSales .containerInput .subTitleInput {
        font-size: 12px;
    }

    .containerAttendantSales .containerInput .inputStyle {
        height: 40px;
        font-size: 17px;
    }


    .containerAttendantSales .containerButtons .buttonSimulator,
    .containerAttendantSales .containerButtons .buttonSave,
    .containerAttendantSales .containerButtons .buttonReset {
        font-size: 17px;
        width: 180px;
        height: 40px;
    }
}

@media only screen and (max-width: 1153px) {
    .containerAttendantSales .contentWeb {
        display: none;
    }

    .containerAttendantSales .contentMobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .containerAttendantSales .containerMenu {
        display: none;
    }

    .containerAttendantSales .containerButtons .buttonSimulator,
    .containerAttendantSales .containerButtons .buttonSave,
    .containerAttendantSales .containerButtons .buttonReset {
        font-size: 12px;
        width: 140px;
        height: 40px;
    }

    .containerAttendantSales .containerButtons .buttonSave img {
        width: 8px;
    }

    .containerAttendantSales .titlePage {
        display: block;
        font-size: 24px;
        font-weight: 700;

    }

    .containerAttendantSales .containerButtons {
        align-self: center;
    }

    .containerAttendantSales .cardChatHistory {
        font-size: 16px;
    }

    .containerAttendantSales .cardChatHistory .title {
        font-size: 18px;
    }

}