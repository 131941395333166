@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Global Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
}

/* Base Styles */
.containerRegister {
    background-color: #F3F5F8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.headerRegister,
.footerRegister {
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.headerRegister {
    top: 0;
    height: 66px;
    background-color: #FFFFFF;
    color: #102649;
}

.footerRegister {
    bottom: 0;
    height: 50px;
    background-color: #102649;
    color: #FFFFFF;
    font-size: 16px;
}

.headerRegister img {
    width: 150px;
}

.containerCenterRegister {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0; /* Adjusted for header and footer height */
    padding: 20px;
    position: relative;
    z-index: 5;
    width: 100%;
}

.boxRegister {
    max-width: 663px;
    width: 100%;
    padding: 50px;
    border-radius: 20px;
    background-color: #FFFFFF;
}

.headerBoxRegister {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.headerBoxRegister .text {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 28px;
    font-weight: 700;
    color: #102649;
}

.headerBoxRegister .subText {
    font-size: 18px;
    font-weight: 400;
    color: #102649;
}

.formRegister {
    display: flex;
    flex-direction: column;
}

.inputGroup {
    margin-bottom: 20px;
}

.labelInputRegister {
    font-size: 16px;
    font-weight: 700;
    color: #102649;
    margin-bottom: 5px;
}

.inputRegister {
    width: 100%;
    height: 50px;
    padding: 14px;
    font-size: 16px;
    background-color: #F3F5F8;
    border: none;
    border-radius: 20px;
    transition: background-color 0.3s, border 0.3s;
}

.inputRegister:focus {
    outline: none;
    background-color: #FFFFFF;
    border: 2px solid #77E191;
}

.errorText {
    font-size: 14px;
    color: darkred;
    margin-top: 5px;
    align-self: flex-end;
}

.buttonRegister {
    align-self: flex-end;
    margin-top: 20px;
    padding: 15px 25px;
    font-size: 21px;
    font-weight: 700;
    color: #102649;
    background-color: #77E191;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.buttonRegister:hover {
    background-color: #6AD084;
}

.buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.image1,
.image2 {
    position: absolute;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    z-index: 1;
}

.image1 {
    top: 0;
    left: 0;
}

.image2 {
    bottom: 0;
    right: 0;
}

/* Responsive Adjustments */
@media (max-height: 800px), (max-width: 570px) {
    .containerCenterRegister {
        margin: 70px 0;
    }
    .boxRegister {
        padding: 30px;
        border-radius: 12px;
    }
    .headerBoxRegister .text {
        font-size: 24px;
    }
    .headerBoxRegister .subText,
    .labelInputRegister {
        font-size: 14px;
        margin-bottom: 4px;
    }
    .inputRegister {
        height: 50px;
        font-size: 14px;
        border-radius: 12px;
    }
    .inputGroup {
        margin-bottom: 15px;
    }
    .buttonRegister {
        font-size: 18px;
        margin-top: 20px;
    }
    .footerRegister {
        height: 40px;
        font-size: 14px;
    }
    .headerRegister {
        height: 60px;
    }
    .headerRegister img {
        width: 120px;
    }
}

@media (max-width: 570px) {
    .boxRegister {
        padding: 20px;
    }
    .headerBoxRegister .text {
        font-size: 20px;
    }
    .inputRegister {
        height: 40px;
    }
    .buttonRegister {
        font-size: 16px;
    }
    .footerRegister {
        font-size: 12px;
    }
    .headerRegister {
        height: 50px;
    }
}

@media (min-width: 1920px) {
    .containerCenterRegister {
        margin: 100px 0;
    }
    .boxRegister {
        padding: 60px;
    }
    .headerBoxRegister .text {
        font-size: 32px;
    }
    .headerBoxRegister .subText {
        font-size: 20px;
    }
    .labelInputRegister {
        font-size: 18px;
        margin-bottom: 8px;
    }
    .inputRegister {
        height: 70px;
        font-size: 18px;
    }
    .inputGroup {
        margin-bottom: 25px;
    }
    .buttonRegister {
        font-size: 24px;
        margin-top: 30px;
    }
    .footerRegister {
        height: 60px;
        font-size: 18px;
    }
    .headerRegister {
        height: 80px;
    }
}
