@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerTableTransactions {
  padding: 30px;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerTableTransactions .containerInputsTableTransactions {
  min-height: 45px;
}


.containerTableTransactions .containerInputsTableTransactions .inputSearch {
  width: 100%;
  height: 35px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  font-size: 18px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #dddddd;
}


.containerTableTransactions .cardTableTransactions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 30vh;
  overflow: auto;
}

.containerTableTransactions .cardTableTransactions .header,
.containerTableTransactions .cardTableTransactions .containerItens .client {
  display: flex;
  width: 100%;
}

.containerTableTransactions .cardTableTransactions .header div,
.containerTableTransactions .cardTableTransactions .containerItens .client div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  text-align: center;
  flex: 1;
}

.containerTableTransactions .cardTableTransactions .containerItens .client div {
  padding: 5px;
}

.containerTableTransactions .cardTableTransactions .header div {
  font-size: 24px;
  font-weight: 700;
}

.containerTableTransactions .cardTableTransactions .header .containerIconFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.containerTableTransactions .cardTableTransactions .header .containerIconFilter img {
  cursor: pointer;
  width: 25px;
}

.containerTableTransactions .cardTableTransactions .header .containerIconFilter button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #a62527;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  border-radius: 2px;
  font-size: 18px;
}

.containerTableTransactions .cardTableTransactions .containerItens .client div {
  font-size: 21px;
  font-weight: 400;
}

.containerTableTransactions .cardTableTransactions .containerItens .buttonMoreCheck {
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: #77E191;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
}

.containerTableTransactions .cardTableTransactions .containerItens .containerName {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerTableTransactions .cardTableTransactions .containerItens .containerName .userIMage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}


.containerTableTransactions .containerPagination {
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  font-weight: 400;
  margin-top: 20px;
}

.containerTableTransactions .containerPagination .pagination {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 400px;
  justify-content: space-between;
}

.containerTableTransactions .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #102649;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.containerTableTransactions .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #102649;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}

.containerTableTransactions .pagination .containerButton:hover {
  opacity: 55%;
}

.containerTableTransactions .pagination .containerButton .left {
  transform: rotate(-180deg);
}

.containerTableTransactions .containerPagination .totalPage {
  display: flex;
  gap: 10px;
}

.containerTableTransactions .containerPagination .totalPage .text {
  font-size: 21px;
  font-weight: 700;
}

@media only screen and (max-width: 1920px) {
  .containerTableTransactions {
    padding: 20px;
  }

  .containerTableTransactions .containerInputsTableTransactions .inputSearch {
    height: 35px;
    font-size: 16px;
  }


  .containerTableTransactions .cardTableTransactions .header div {
    font-size: 21px;
  }


  .containerTableTransactions .cardTableTransactions .header .containerIconFilter img {
    width: 20px;
  }

  .containerTableTransactions .cardTableTransactions .header .containerIconFilter button {
    width: 20px;
    height: 20px;
    font-size: 16px;
  }

  .containerTableTransactions .cardTableTransactions .containerItens .client div {
    font-size: 18px;
  }

  .containerTableTransactions .cardTableTransactions .containerItens .buttonMoreCheck {
    width: 100%;
    height: 40px;
    font-size: 18px;
  }

  .containerTableTransactions .cardTableTransactions .containerItens .containerName .userIMage {
    width: 30px;
    height: 30px;
  }

  .containerTableTransactions .containerPagination {
    font-size: 16px;
  }

  .containerTableTransactions .containerPagination .pagination {
    gap: 15px;
  }

  .containerTableTransactions .pagination .containerButton {
    font-size: 16px;
  }


  .containerTableTransactions .containerPagination .totalPage .text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1437px) {
  .containerTableTransactions {
    padding: 5px 20px;
  }

  .containerTableTransactions .cardTableTransactions .header div,
  .containerTableTransactions .cardTableTransactions .containerItens .client div {
    flex: none;
    width: 200px;
  }

  .containerTableTransactions .containerInputsTableTransactions .inputSearch {
    height: 35px;
    font-size: 14px;
  }


  .containerTableTransactions .cardTableTransactions .header div {
    font-size: 18px;
  }


  .containerTableTransactions .cardTableTransactions .containerItens .client div {
    font-size: 16px;
  }

  .containerTableTransactions .cardTableTransactions .containerItens .buttonMoreCheck {
    height: 35px;
    font-size: 16px;
  }
}


@media only screen and (max-width: 720px) {

  .containerTableTransactions .containerPagination {
    font-size: 14px;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  .containerTableTransactions .pagination .containerButton {
    font-size: 14px;
  }

  .containerTableTransactions .containerPagination .pagination {
    gap: 10px;
    width: 80vw;
  }

  .containerTableTransactions .containerPagination .totalPage .text {
    font-size: 16px;
  }

  .containerTableTransactions .containerPagination .totalPage {
    justify-content: flex-end;
  }
}