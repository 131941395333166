@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerSummary {
  flex: 1;
  padding: 35px;
  background-color: #FFFFFF;
  margin: 35px;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerSummary .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
}

.containerSummary .subTitle {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.containerSummary .containerCard {
  display: flex;
  gap: 30px;
  flex: 1;
  margin-top: 20px;
}

.containerSummary .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.containerSummary .containerCard .card .containerInput .title {
  font-size: 21px;
}

.containerSummary .containerCard .card .containerInput .subTitle {
  margin-top: 0px;
}

.containerSummary .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
}

.containerSummary .containerCard .line {
  border: 1px solid #DEE4EF;
}

.containerSummary .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 15px;
}

.containerSummary .buttonNextCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
}

.containerSummary .buttonBackCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #FFFFFF;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: 1px solid #77E191;
}

.containerSummary .buttonBackCRedCard img {
  transform: rotate(-180deg); 
}

@media only screen and (max-width: 842px) {
  .containerSummary .title {
    font-size: 21px;
  }
  
  .containerSummary .subTitle {
    font-size: 16px;
  }
  
  .containerSummary .containerCard {
    gap: 40px;
    flex-direction: column;
  }
  
  .containerSummary .containerCard .card {
    gap: 20px;
  }
  
  .containerSummary .containerCard .card .containerInput .title {
    font-size: 18px;
  }
  
  .containerSummary .containerCard .card .containerInput {
    gap: 10px;
  }
  
  .containerSummary .containerCard .card .containerInput .inputCredCard {
    height: 50px;
    padding: 10px;
    font-size: 18px;
  }
  
  .containerSummary .containerButtonsSumary {
    justify-content: flex-start;
    gap: 10px;
  }
  
  .containerSummary .buttonNextCRedCard {
    font-size: 16px;
    height: 40px;
    padding: 0px 10px;
  }
  
  .containerSummary .buttonBackCRedCard {
    font-size: 16px;
    height: 40px;
    padding: 0px 10px;
  }
}

@media only screen and (max-width: 380px) {
  .containerSummary .containerButtonsSumary {
    justify-content: center;
    gap: 5px;
  }

  .containerSummary .buttonNextCRedCard {
    font-size: 16px;
    height: 40px;
    padding: 0px 1px;
  }
  
  .containerSummary .buttonBackCRedCard {
    font-size: 16px;
    height: 40px;
    padding: 0px 5px;
  }
}