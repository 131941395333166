@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerCardPlan {
  display: flex;
  flex: 1;
  gap: 20px;
  padding: 35px;
  flex-wrap: wrap;
  justify-content: center;
}

.containerCardPlan .cardPlan {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  font-family: "Outfit", sans-serif;
  color: #102649;
  font-size: 18px;
  font-weight: 400;
  border-radius: 20px;
  max-width: 350px;
}

.containerCardPlan .cardPlan .title {
  background-color: #102649;
  color: #FFFFFF;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 28px;
  border-radius: 20px 20px 0 0;
}

.containerCardPlan .cardPlan .franquia {
  background-color: #E8EBF1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  width: 100%;
  /* height: 80px; */
}

.containerCardPlan .cardPlan .franquia .containerIMg {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.containerCardPlan .cardPlan .line {
  border-top: 1px solid #E8EBF1
}

.containerCardPlan .cardPlan .containerBotton {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
  height: 100%;
}

.containerCardPlan .cardPlan .containerBotton2 {
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}


.containerCardPlan .containerValue {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 25px;
}

.containerCardPlan .containerValue .textValue {
  font-size: 36px;
  font-weight: 400;
  display: flex;
  align-items: last baseline;
  gap: 3px;
}

.containerCardPlan .containerValue .textCifran {
  font-size: 26px;
}

.containerCardPlan .containerValue .subtextValue {
  font-size: 16px;
  font-weight: 400;
}

.containerCardPlan .containerBotton2 .buttonSelectṔlan {
  width: 100%;
  height: 56px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonPlanTrial {
  font-family: "Outfit", sans-serif;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 1920px) {
  .containerCardPlan .cardPlan {
    font-size: 16px;
  }
  
  .containerCardPlan .cardPlan .title {
    font-size: 26px;
  }

  .containerCardPlan .containerValue .textCifran {
    font-size: 24px;
  }
  
  .containerCardPlan .containerValue .subtextValue {
    font-size: 14px;
    font-weight: 400;
  }
  
  .containerCardPlan .containerBotton .buttonSelectṔlan {
    font-size: 19px;
  }
  
  .buttonPlanTrial {
    font-size: 16px;
  }
}

@media only screen and (max-width: 540px) {
  .containerCardPlan .cardPlan {
    font-size: 18px;
  }
  
  .containerCardPlan .cardPlan .title {
    font-size: 24px;
  }

  .containerCardPlan .containerValue .textCifran {
    font-size: 28px;
  }
  
  .containerCardPlan .containerValue .subtextValue {
    font-size: 16px;
    font-weight: 400;
  }
  
  .containerCardPlan .containerBotton .buttonSelectṔlan {
    font-size: 18px;
  }
  
  .buttonPlanTrial {
    font-size: 18px;
    color: #102649;
  }

  .containerCardPlan .cardPlan .franquia .containerIMg {
    width: 30%;
    margin-right: 0px;
  }
  
  .containerCardPlan .cardPlan .franquia img {
    width: 43px;
    height: 43px;
  }
}