@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerCheckout {
  display: flex;
  gap: 30px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  padding: 35px;
  margin-top: 70px;
  flex: 1;
}

.containerCheckout .headerCheckout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
}

.containerCheckout .cardCheckout {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 20px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.containerCheckout .containerText {
  display: flex;
  flex-wrap: nowrap;
}

.containerCheckout .title {
  font-weight: 700;
  font-size: 28px;
  align-self: center;
  margin-bottom: 15px;
  margin-top: 60px;
}

.containerCheckout .containerSlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 30px;
}

.containerCheckout .containerSlider .cardSlider {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 20px;
}

.containerCheckout .containerSlider .titleHea {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
}

.containerCheckout .containerSlider .subTitle {
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerCheckout .Checkout-used {
  background-color: #F4F6F8;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerCheckout .Checkout-used .title {
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerCheckout .Checkout-used .subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #102649;
}

.containerCheckout .plans {
  display: flex;
  flex-direction: column;
}

.containerCheckout .containerText {
  display: flex;
  gap: 10px;
  height: 100%;
}

.containerCheckout .text {
  font-size: 21px;
  font-weight: 700;
  color: #102649;
}

.containerCheckout .cicle {
  color: #77E191;
  font-weight: 700;
}

.containerCheckout .subText {
  font-size: 21px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
  margin-bottom: 10px;
}

.containerCheckout .textFont {
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerSlider .buttonSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

.containerSlider .containerResume {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  justify-content: space-between;
}

.containerSlider .credcardsAll {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.containerSlider .containerCredCards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #f4f5f9;
  width: 45%;
}

.containerSlider .containerCredCards .cardCredit {
  display: flex;
  flex-direction: column;
}

.containerSlider .containerCredCards .cardCredit .text {
  font-size: 21px;
  font-weight: 700;
}

.containerSlider .containerCredCards .cardCredit .subtitle {
  font-size: 21px;
  font-weight: 400;
}


@media only screen and (max-width: 1920px) {
  .containerCheckout {
    gap: 25px;
    padding: 25px;
  }

  .containerCheckout .cardCheckout {
    background-color: #FFFFFF;
    padding: 25px;
    font-size: 19px;
  }

  .containerCheckout .title {
    font-size: 26px;
  }

  .containerCheckout .containerSlider .titleHea {
    font-size: 26px;
  }

  .containerCheckout .containerSlider .subTitle {
    font-size: 19px;
  }


  .containerCheckout .Checkout-used .title {
    font-size: 17px;
  }

  .containerCheckout .Checkout-used .subtitle {
    font-size: 20px;
  }

  .containerCheckout .Checkout-used img {
    width: 40px;
  }

  .containerSlider.plans .text {
    font-size: 18px;
  }

  .containerCheckout .subText {
    font-size: 16px;
  }

  .containerSlider .buttonSlide {
    padding: 10px 17px;
    font-size: 18px;
    gap: 10px;
  }

  .containerSlider .containerCredCards .cardCredit .text {
    font-size: 18px;
    font-weight: 700;
  }
  
  .containerSlider .containerCredCards .cardCredit .subtitle {
    font-size: 18px;
    font-weight: 400;
  }
}

@media only screen and (max-width: 1094px) {
  .containerCheckout {
    flex-direction: column;
  }

  .containerCheckout .containerSlider .subTitle {
    font-size: 18px;
  }

  .containerSlider .buttonSlide {
    padding: 10px 17px;
    font-size: 16px;
    gap: 10px;
    align-self: flex-start !important;
  }

  .containerCheckout .containerSlider .titleHea {
    font-size: 20px;
  }

  .containerSlider .containerCredCards .cardCredit .text {
    font-size: 16px;
    font-weight: 700;
  }
  
  .containerSlider .containerCredCards .cardCredit .subtitle {
    font-size: 16px;
    font-weight: 400;
  }

}


@media only screen and (max-width: 600px) {

  .containerSlider .containerCredCards {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .containerSlider .containerCredCards .cardCredit {
    flex-direction: row;
  }
}