@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerChatHistory {
    display: flex;
    gap: 30px;
    font-family: "Outfit", sans-serif;
    color: #102649;
    padding: 60px;
    /* margin-top: 20px; */
    flex: 1;
}

.containerChatHistory .headerChatHistory {
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 400px;
}

.containerChatHistory .cardChatHistory {
    background-color: #FFFFFF;
    padding: 40px 30px;
    border-radius: 20px;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.containerChatHistory .containerText {
    display: flex;
    flex-wrap: nowrap;
}

.containerChatHistory .containerText strong {
    flex-shrink: 0;
    margin-right: 5px;
}

.containerChatHistory .containerText span {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerChatHistory .cardChatHistory .title {
    font-weight: 700;
    font-size: 28px;
    align-self: center;
    margin-bottom: 15px;
    /* margin-top: 60px; */
}

.containerChatHistory .cardChatHistory .containerImg {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerChatHistory .cardChatHistory .containerImg .iconUser {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.containerChatHistory .cardScore {
    background-color: #FFFFFF;
    padding: 30px 25px;
    border-radius: 20px;
    display: flex;
    gap: 15px;
    align-items: center;
}

.containerChatHistory .cardScore img {
    width: 60px;
    height: 60px;
}

.containerChatHistory .cardScore .containerText {
    display: flex;
    flex-direction: column;
}


.containerChatHistory .cardScore .containerText .title {
    font-weight: 400;
    font-size: 21px;
}


.containerChatHistory .cardScore .containerText .subtitle {
    font-weight: 700;
    font-size: 24px;
}

.containerSumary {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 3;
}

.containerSumary .containerBox {
    background-color: #FFFFFF;
    padding: 30px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerSumary .containerBox .title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 24px !important;
}

.containerSumary .containerBox img {
    width: 21px !important;
    height: 21px !important;
}

.containerSumary .containerBox .text {
    font-weight: 400;
    font-size: 18px !important;
}

.containerSumary .containerBox .containerHeader2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 70px; */
    margin-bottom: 20px;
}

.containerSumary .containerBox .containerHeader2 .containerIcons {
    display: flex;
    gap: 10px;
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter img {
    cursor: pointer;
    width: 25px;
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter button {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: #a62527;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 700;
    border-radius: 2px;
    font-size: 18px;
}


.containerSumary .containerBox .containerHeader2 .containerIconFilter img {
    width: 20px;
    height: 20px;
}

.containerSumary .containerBox .containerHeader2 .containerIconFilter button {
    width: 20px;
    height: 20px;
    font-size: 16px;
}

.containerSumary .containerBox .inputSearch {
    width: 100%;
    height: 35px;
    font-family: "Outfit", sans-serif;
    color: #102649;
    font-size: 18px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #dddddd;
}


@media only screen and (max-width: 1450px) {
    .containerChatHistory {
        flex-direction: column;
        padding: 40px;
    }

    .containerChatHistory .headerChatHistory {
        max-width: 100%;
    }
}

@media only screen and (max-width: 1094px) {
    .containerChatHistory {
        flex-direction: column;
        padding: 20px;
    }

    .containerChatHistory {
        flex-direction: column;
        font-size: 16px;
    }

    .containerChatHistory .cardScore .containerText .title {
        font-size: 18px;
    }


    .containerChatHistory .cardScore .containerText .subtitle {
        font-size: 21px;
    }

    .containerSumary .containerBox {
        padding: 20px;
    }


    .containerSumary .containerBox .title {
        font-size: 18px;
    }


    .containerSumary .containerBox .text {
        font-size: 16px;
    }

    .containerSumary .containerBox .containerHeader2 .containerIconFilter button {
        font-size: 14px;
    }

    .containerSumary .containerBox .containerHeader2 .containerIconFilter button {
        font-size: 14px;
    }

    .containerSumary .containerBox .inputSearch {
        font-size: 14px;
    }

    .containerChatHistory .cardChatHistory .containerImg {
        width: 140px;
        height: 140px;
    }

    .containerChatHistory .cardScore img {
        width: 52px;
        height: 52px;
    }

    .containerSumary .containerBox img {
        width: 21px !important;
        height: 21px !important;
    }
}