@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --primary-color: #102649;
  --white: #FFFFFF;
  --border-radius: 20px;
  --font-family: "Outfit", sans-serif;
  --success-color: #77E191;
  --error-color: #CE3C3C;
  --border-color: #DEE4EF;
  --bg-light: #ECEFF4;
}

/* Layout containers */
.containerDashboard {
  padding: 30px;
  display: flex;
  gap: 20px;
}

.containerDashboard .containerLeft,
.containerDashboard .containerRigh {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerDashboard .containerRigh {
  flex: 1.5;
}

.containerDashboard .containerLeft {
  flex: 2;
}

.containerDashboard .line {
  height: 100%;
  width: 2px;
  background-color: var(--border-color);
}

/* Header styles */
.containerDashboard .containerHeader {
  display: flex;
  flex-direction: column;
}

.containerDashboard .containerHeader .title,
.containerDashboard .containerHeader .text {
  font-family: var(--font-family);
  text-align: left;
  color: var(--primary-color);
}

.containerDashboard .containerHeader .title {
  font-weight: 700;
  font-size: 28px;
}

.containerDashboard .containerHeader .text {
  font-weight: 400;
  font-size: 24px;
}

/* Info cards - UPDATED */
.containerDashboard .containerInfosLeadsSends {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
  color: var(--primary-color);
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  gap: 20px;
}

.containerDashboard .containerInfosLeadsSends .containerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 25px 20px;
  gap: 10px;
  background: var(--white);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: all 0.2s ease;
}

/* Added hover effect for cards */
.containerDashboard .containerInfosLeadsSends .containerInfo:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
  font-weight: 700;
  font-size: 48px;
  gap: 10px;
  display: flex;
  align-items: center;
}

/* Responsive adjustments */
@media only screen and (max-width: 1920px) {
  .containerDashboard .containerInfosLeadsSends {
    font-size: 16px;
  }
  
  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
    font-size: 42px;
  }
}

@media only screen and (max-width: 883px) {
  .containerDashboard .containerInfosLeadsSends {
    font-size: 14px;
  }
  
  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
    font-size: 36px;
  }
  
  .containerDashboard .containerInfosLeadsSends .containerInfo {
    padding: 20px 15px;
  }
}

/* Token container - UPDATED */
.containerDashboard .containerTokens {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  gap: 15px;
  background: var(--white);
  border-radius: var(--border-radius);
  margin-top: 0;
  height: auto;
}

.containerDashboard .containerTokens .containerHeaderTokens {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 8px 12px;
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-color);
}

.containerDashboard .containerTokens .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20px;
}

.containerDashboard .containerTokens .fill {
  background-color: var(--bg-light);
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.containerDashboard .containerTokens .leads {
  background-color: var(--success-color);
  border-radius: var(--border-radius);
  height: 100%;
  transition: width 0.3s ease;
}

/* Alert container */
.containerAlertGoogle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: var(--white);
  border: 2px solid var(--error-color);
  padding: 15px 10px;
  border-radius: var(--border-radius);
  gap: 20px;
}

.containerAlertGoogle .containerText {
  display: flex;
  align-items: center;
  gap: 20px;
}

.containerAlertGoogle .text {
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: 400;
  color: var(--primary-color);
}

.containerAlertGoogle .button {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  font-family: var(--font-family);
  font-size: 21px;
  font-weight: 700;
  color: var(--primary-color);
  background: var(--success-color);
  cursor: pointer;
  padding: 10px 15px;
  border-radius: var(--border-radius);
}

/* Responsive styles */
@media only screen and (max-width: 1920px) {
  .containerDashboard {
    --title-size: 25px;
    --text-size: 18px;
    --large-text: 55px;
    --button-radius: 15px;
  }

  .containerDashboard .containerHeader .title { font-size: var(--title-size); }
  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends { font-size: var(--text-size); }
  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg { font-size: var(--large-text); }
  .containerDashboard .containerTokens .containerHeaderTokens { font-size: 16px; }
  .containerDashboard .containerTokens .leads { font-size: 12px; }
  .containerAlertGoogle .text,
  .containerAlertGoogle .button { font-size: var(--text-size); }
  .containerAlertGoogle .button { border-radius: var(--button-radius); }
}

@media only screen and (max-width: 1319px) {
  .containerDashboard {
    flex-direction: column;
  }
  .containerDashboard .line {
    display: none;
  }
}

@media only screen and (max-width: 883px) {
  .containerDashboard {
    padding: 25px;
    gap: 10px;
    --small-text: 14px;
    --title-size: 18px;
    --icon-size: 32px;
    --padding: 15px;
  }

  .containerDashboard .containerHeader .title { font-size: var(--title-size); }
  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends { font-size: 12px; }
  
  .containerDashboard .containerInfosLeadsSends .containerInfo {
    padding: 25px var(--padding);
  }
  
  .containerDashboard .containerInfosLeadsSends .containerInfo img {
    width: var(--icon-size);
  }

  .containerAlertGoogle .text,
  .containerAlertGoogle .button { font-size: var(--small-text); }
  
  .containerAlertGoogle .button img {
    width: 8px;
  }

  .containerDashboard .containerTokens .containerHeaderTokens { font-size: 14px; }
  .containerDashboard .containerTokens .leads { font-size: 11px; }
}

/* Card Leads List - NEW/UPDATED */
.containerCardLeads {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 20px;
  gap: 10px;
}

.containerCardLeads .containerHeaderTokens {
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.containerCardLeads .containerHeaderTokens:hover {
  background-color: var(--bg-light);
  border-radius: 8px;
  transform: translateX(5px);
}

.containerCardLeads .containerHeaderTokens span:first-child {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 400;
  color: var(--primary-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}

.containerCardLeads .containerHeaderTokens span:last-child {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-color);
  white-space: nowrap;
}

/* Responsive adjustments */
@media only screen and (max-width: 1920px) {
  .containerCardLeads .containerHeaderTokens span:first-child {
    font-size: 12px;
  }
  .containerCardLeads .containerHeaderTokens span:last-child {
    font-size: 13px;
  }
}

@media only screen and (max-width: 883px) {
  .containerCardLeads .containerHeaderTokens span:first-child {
    font-size: 11px;
  }
  .containerCardLeads .containerHeaderTokens span:last-child {
    font-size: 12px;
  }
}