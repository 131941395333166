@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerSuporte {
  width: 60vw;;
  padding: 40px 50px;
  background-color: #FFFFFF;
  margin: 60px;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 20;
}

.containerSuporte .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.containerSuporte .subTitle {
  font-size: 16px;
  font-weight: 400;
  margin-top: 1px;
  color: #8291A9;
}

.containerSuporte .containerCard {
  display: flex;
  gap: 40px;
  flex: 1;
  margin-top: 20px;
}

.containerSuporte .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.containerSuporte .containerCard .card .containerInput .title {
  font-size: 21px;
}

.containerSuporte .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: 140px; */
}

.containerSuporte .containerCard .card .containerInput .inputSuporte {
  width: 100%;
  height: 60px;
  border-radius: 20px;
  background-color: #F3F5F8;
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: 400;
  margin-top: 10px;
}


.containerSuporte .containerCard .line {
  border: 1px solid #DEE4EF;
}

.containerSuporte .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.containerSuporte .buttonNextSuporte {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
}

.containerSuporte .buttonBackSuporte {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #FFFFFF;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: 1px solid #77E191;
}

.containerSuporte .buttonBackSuporte img {
  transform: rotate(-180deg); 
}

.containerSuporte .containerInput .inputStyle {
  width: 100%;
  border-radius: 20px;
  background-color: #F3F5F8;
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: 400;
  cursor: pointer;
  resize: none;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerImage {
  position: relative;
  display: flex;
}

.containerImage .robo {
  position:absolute;
  right: 0;
  width: 500px;
  object-fit: cover;
  /* z-index: 1; */
  margin-top: 5%;
}

@media only screen and (max-width: 1500px) {
  .containerAttendant .containerInput .inputStyle {
      font-size: 19px;
  }
  .containerImage .robo {
    display: none;
  }
  
  .containerSuporte {
   flex: 1;
  }
  
}

@media only screen and (max-width: 1431px) {
  .containerAttendant .containerInput .inputStyle {
      font-size: 17px;
  }
}

@media only screen and (max-width: 842px) {
  .containerSuporte {
    padding: 30px;
    margin: 20px;
  }
  .containerSuporte .title {
    font-size: 21px;
  }
  
  .containerSuporte .subTitle {
    font-size: 16px;
  }
  
  .containerSuporte .containerCard {
    gap: 20px;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .containerSuporte .containerCard .card {
    gap: 20px;
  }
  
  .containerSuporte .containerCard .card .containerInput .title {
    font-size: 18px;
  }
  
  .containerSuporte .containerCard .card .containerInput {
    gap: 10px;
  }
  
  .containerSuporte .containerCard .card .containerInput .inputSuporte {
    height: 50px;
    padding: 10px;
    font-size: 18px;
  }
  
  
  .containerSuporte .containerCard .line {
    display: none;
  }
  
  .containerSuporte .containerButtonsSumary {
    justify-content: flex-end;

  }
  
  .containerSuporte .buttonNextSuporte {
    font-size: 18px;
  }
  
  .containerSuporte .buttonBackSuporte {
    font-size: 18px;
  }
}