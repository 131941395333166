@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --primary-color: #102649;
  --white: #FFFFFF;
  --success-color: #77E191;
  --error-color: #CE3C3C;
  --border-radius-lg: 20px;
  --border-radius-md: 16px;
  --border-radius-sm: 10px;
  --font-family: "Outfit", sans-serif;
  --menu-line-color: #CDD3DD;
  --input-bg: #F3F5F8;
  --disabled-color: rgb(216, 213, 213);
  --subtitle-color: #8291A9;
  --warning-bg: #FFFBEE;
  --warning-border: #CDD3DD;
  --warning-text: #102649;
  --gradient-bg: linear-gradient(180deg, #f1fcf4 0%, #FFFFFF 100%);
}

/* Base Container Styles */
.containerAttendant,
.containerBusinessSettings {
  padding: 60px;
  font-family: var(--font-family);
  color: var(--primary-color);
}

.containerBusinessSettings {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Menu Styles */
.containerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.containerMenu .menu {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  font-size: 18px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menuSelected {
  background: var(--white);
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  font-weight: 700;
}

.containerMenu .line {
  width: 2px;
  height: 23px;
  background: var(--menu-line-color);
}

.disabledMenus {
  color: var(--disabled-color) !important;
}

/* Input Container Styles */
.containerInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerInput .title {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 700;
}

.containerInput .subTitleInput {
  font-size: 16px;
  color: var(--subtitle-color);
  margin-bottom: 15px;
}

.containerInput .inputStyle {
  width: 100%;
  height: 60px;
  border-radius: var(--border-radius-lg);
  background-color: var(--input-bg);
  border: none;
  padding: 10px;
  font-size: 21px;
  font-family: var(--font-family);
  color: var(--primary-color);
  resize: none;
  cursor: pointer;
}

/* Button Styles */
.containerButtons {
  display: flex;
  gap: 20px;
  align-self: flex-end;
}

.buttonSimulator,
.buttonSave,
.buttonReset {
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  gap: 10px;
  cursor: pointer;
  width: 240px;
  height: 56px;
}

.buttonSimulator {
  border: 2px solid var(--success-color);
  background-color: var(--white);
}

.buttonSave {
  background-color: var(--success-color);
}

.buttonReset {
  background-color: var(--error-color);
  color: var(--white);
}

/* Info Container Styles */
.containerInfo {
  padding: 20px;
  background-color: var(--warning-bg);
  border: 1px solid var(--warning-border);
  border-radius: var(--border-radius-sm);
  font-size: 18px;
  color: var(--warning-text);
}

.containerInfo strong {
  font-weight: 700;
}

/* Modal Styles */
.modalContent {
  background: var(--gradient-bg);
  border-radius: var(--border-radius-lg);
  padding: 25px;
  width: 700px;
  max-width: 90%;
  position: relative;
}

/* Responsive Styles */
@media (max-width: 1500px) {
  .containerAttendant,
  .containerBusinessSettings {
    padding: 20px;
  }

  .containerMenu .menu {
    font-size: 16px;
  }

  .containerInput .title { font-size: 19px; }
  .containerInput .subTitleInput { font-size: 14px; }
  .containerInput .inputStyle {
    height: 50px;
    font-size: 19px;
  }

  .buttonSimulator,
  .buttonSave,
  .buttonReset {
    font-size: 19px;
    width: 200px;
    height: 46px;
  }
}

@media (max-width: 1153px) {
  .contentWeb { display: none; }
  .contentMobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .containerMenu { display: none; }
  .titlePage {
    display: block;
    font-size: 24px;
    font-weight: 700;
  }

  .containerButtons {
    align-self: center;
  }

  .buttonSimulator,
  .buttonSave,
  .buttonReset {
    font-size: 12px;
    width: 140px;
    height: 40px;
  }

  .buttonSave img {
    width: 8px;
  }
}

@media (max-width: 900px) {
  .containerBusinessSettings {
    padding: 15px;
  }

  .containerMenu .menu {
    font-size: 16px;
    padding: 10px 15px;
    height: 50px;
  }

  .containerInfo { font-size: 14px; }
  .subMenuContainer { padding-left: 30px; }

  .buttonSimulator,
  .buttonSave,
  .buttonReset {
    width: 160px;
    height: 35px;
  }
}
