/* SignUpSales.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f1fcf4;
    min-height: 100vh;
    padding: 20px;
  }
  
  .logo {
    width: 200px;
    margin-bottom: 20px;
  }
  
  .signup-form {
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
  }
  
  .signup-form form {
    display: flex;
    flex-direction: column;
  }
  
  .document-section,
  .terms-section {
    margin-bottom: 20px;
  }
  
  .document-type {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
  }
  
  .document-type label {
    display: flex;
    align-items: center;
  }
  
  .document-type input {
    margin-right: 5px;
  }
  
  .terms-section label {
    display: flex;
    align-items: center;
  }
  
  .terms-section input {
    margin-right: 10px;
  }
  
  .error-text {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .submit-button {
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background-color: #77e191;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .submit-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  .documentTypeOptions {
    display: flex;
    gap: 20px; /* Adds space between the radio buttons */
    margin-top: 10px; /* Optional: Adds space above the options */
  }
  
  .radioOption {
    display: flex;
    align-items: center;
  }
  .buttonRegister:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }