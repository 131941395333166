.containerStep3 {
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.containerStep3 .containerInputs {
  display: flex;
  flex: 1;
  gap: 30px;
  position: relative;
}

.containerStep3 .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media only screen and (max-width: 1153px) {

}
