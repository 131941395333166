.containerStep6 {
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.containerStep6 .containerButtonsContas {
  display: flex;
  gap: 20px;
  align-items: center;
}

.containerStep6 .containerButtonsContas .containerNumber {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 260px;
  height: 56px;
  font-size: 21px;
  font-weight: 400;
}

.containerStep6 .containerButtonsContas .containervinculo {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 360px;
  height: 56px;
  font-size: 21px;
  font-weight: 400;
}

.containerStep6 .containerButtonsContas .button {
  display: flex;
  gap: 2px;
  align-items: center;
  justify-content: center;
  width: 335px;
  height: 56px;
  border-radius: 16px;
  cursor: pointer;
}

.containerStep6 .containerButtonsContas .button button {
  border: none;
  background-color: transparent;
  font-size: 18px;
font-weight: 700;
}

@media only screen and (max-width: 1153px) {
  .containerStep6 {
    padding: 20px;
  }
  
  .containerStep6 .containerButtonsContas {
    display: flex;
    flex-direction: column;
  }
  
  .containerStep6 .containerButtonsContas .containerNumber {
    width: 100%;
    font-size: 16px;
  }
  
  .containerStep6 .containerButtonsContas .containervinculo {
    width: 100%;
    font-size: 16px;
  }
  
  .containerStep6 .containerButtonsContas .button {
    width: 100%;
    border-radius: 14px;
    height: 45px;
  }
  
  .containerStep6 .containerButtonsContas .button button {
    font-size: 14px;
  }
}
