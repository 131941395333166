.containerStep3 {
  padding: 40px;
  background-color: #FFFFFF;
  border-radius: 20px !important;
  gap: 45px !important;
  display: flex;
  flex-direction: column;
}

.containerStep3 .containerInputs {
  display: flex;
  flex: 1;
  gap: 30px;
  position: relative;
}

.containerStep3 .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}


@media only screen and (max-width: 650px) {
  .containerStep3 {
    border-radius: 20px !important;
    gap: 20px !important;
  }
}
