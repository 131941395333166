.containerStep4Check {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.containerStep4Check .title {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    text-align: center;
    color: #102649;
    margin-bottom: 27px;
}

.containerStep4Check img {
    width: 278px;
    height: 26px;

}


.containerStep4Check .cardContent {
    display: flex;
    flex-direction: column;
    width: 450px;
    height: 652px;
    padding: 37px 34px 37px 34px;
    gap: 34px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px #CDDCE433;
    font-family: Outfit;
    font-size: 19px;
    font-weight: 400;
    line-height: 23.94px;
    text-align: left;
    color: #102649;

}

.containerStep4Check .cardContent .containerInputPhone {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.containerStep4Check .cardContent .containerInputPhone div {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: left;
    color: #8291A9;

}


.containerStep4Check .cardContent .input {
    width: 100%;
    height: 60px;
    top: 36px;
    gap: 0px;
    border-radius: 20px;
    background: #F3F5F8;
    border: none;
    cursor: pointer;
    padding: 0px 20px;
}

.containerStep4Check .cardContent button {
    width: 85%;
    height: 56px;
    border-radius: 16px;
    background: #77E191;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border: none;
    cursor: pointer;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    color: #102649;
    align-self: center;
}

.containerStep4Check .cardContent button img {
    width: 25.94px;
    height: 22.75px;
}

.containerStep4Check .cardContent .buttonBackCheck {
    width: 85%;
    height: 56px;
    border-radius: 16px;
    background: #ffffff !important;
    border: 2px solid #77E191 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    color: #102649;
}

.containerStep4Check .cardContent .nextButton {
    font-family: Outfit;
    font-size: 21px;
    font-weight: 400;
    line-height: 26.46px;
    color: #798DA4;
    align-self: center;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 573px) {
    .containerStep4Check .title {
        font-size: 21px;
        line-height: 25.28px;
        margin-bottom: 15px;
    }
    
    
    .containerStep4Check .cardContent {
        width: 80vw;
        height: 100%;
        padding: 25px 22px 25px 22px;
        gap: 25px;
        font-size: 17px;
    }
    
    
    
    .containerStep4Check .cardContent .input {
        height: 40px;
    }
    
    .containerStep4Check .cardContent button {
        height: 40px;
        font-size: 16px;
        text-align: center;
        line-height: 15px;
    }
    
    
    .containerStep4Check .cardContent .buttonBackCheck {
        height: 40px;
        font-size: 16px;
    }
    
    .containerStep4Check .cardContent button img {
        width: 20px;
        height: 17px;
    }

    .containerStep4Check .cardContent .nextButton {
        font-size: 17px;
    }

}