@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

/* Base Styles */
.containerLogin {
  background-color: #F3F5F8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

/* Header Styles */
.headerLogin {
  position: fixed;
  top: 0;
  width: 100%;
  height: 66px;
  background-color: #FFFFFF;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.headerLogin img {
  width: 150px;
}

.userTypeButtons {
  display: flex;
  gap: 10px;
}

.buttonTypeLogin {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #102649;
  background-color: transparent;
  border: 1px solid #102649;
  border-radius: 16px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.buttonTypeLogin:hover {
  background-color: #102649;
  color: #FFFFFF;
}

.buttonIcon {
  font-size: 16px !important;
  margin-right: 5px;
  color: inherit;
}

/* Main Content Styles */
.containerCenterLogin {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  z-index: 5;
  width: 100%;
  overflow-y: auto; /* Allows scrolling if content overflows */
}

.boxLogin {
  max-width: 538px;
  width: 100%;
  padding: 50px;
  border-radius: 20px;
  background-color: #FFFFFF;
}

.loginTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
  font-weight: 700;
  color: #102649;
  margin-bottom: 30px;
}

.loginIcon {
  font-size: 30px;
  margin-right: 10px;
  color: #102649;
}

.formLogin {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  margin-bottom: 20px;
}

.labelInputLogin {
  font-size: 16px;
  font-weight: 700;
  color: #102649;
  margin-bottom: 5px;
}

.inputLogin {
  width: 100%;
  height: 60px;
  padding: 10px;
  font-size: 16px;
  background-color: #F3F5F8;
  border: none;
  border-radius: 20px;
  transition: background-color 0.3s, border 0.3s;
}

.inputLogin:focus {
  outline: none;
  background-color: #FFFFFF;
  border: 2px solid #77E191;
}

.errorText {
  color: white !important;
  padding: 4px 12px;
  border-radius: 100px;
  font-size: 12px !important;
  margin-top: 8px !important;
  margin-bottom: 10px;
  text-align: left;
  background-color: #FF6347;
  margin-top: 5px;
  align-self: flex-end;
}

.buttonLogin {
  align-self: flex-end;
  margin-top: 20px;
  padding: 15px 25px;
  font-size: 21px;
  font-weight: 700;
  color: #102649;
  background-color: #77E191;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonLogin:hover {
  background-color: #6AD084;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.buttonLink {
  font-size: 16px;
  color: #102649;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

/* Footer Styles */
.footerLogin {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #102649;
  color: #FFFFFF;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Background Images */
.image1,
.image2 {
  position: absolute;
  max-width: 50%;
  max-height: 50%;
  width: auto;
  height: auto;
  object-fit: contain;
  z-index: 1;
}

.image1 {
  top: 0;
  left: 0;
}

.image2 {
  bottom: 0;
  right: 0;
}

/* Responsive Adjustments */
@media (max-width: 570px) {
  .boxLogin {
    padding: 35px;
    border-radius: 12px;
  }

  .headerLogin img {
    width: 120px;
  }

  .labelInputLogin {
    font-size: 14px;
  }

  .inputLogin {
    height: 50px;
    font-size: 14px;
    border-radius: 12px;
  }

  .inputGroup {
    margin-bottom: 15px;
  }

  .buttonLogin {
    font-size: 18px;
    margin-top: 20px;
  }

  .footerLogin {
    height: 40px;
    font-size: 14px;
  }

  .headerLogin {
    height: 50px;
  }

  .buttonTypeLogin {
    font-size: 12px;
    padding: 4px 10px;
  }

  .buttonIcon {
    font-size: 14px;
  }

  .loginIcon {
    font-size: 24px;
  }
}

@media (min-width: 1920px) {
  .boxLogin {
    padding: 60px;
  }

  .labelInputLogin {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .inputLogin {
    height: 70px;
    font-size: 18px;
  }

  .inputGroup {
    margin-bottom: 25px;
  }

  .buttonLogin {
    font-size: 24px;
    margin-top: 30px;
  }

  .footerLogin {
    height: 60px;
    font-size: 18px;
  }

  .headerLogin {
    height: 60px;
  }

  .buttonTypeLogin {
    font-size: 16px;
    padding: 6px 15px;
  }

  .buttonIcon {
    font-size: 18px;
  }

  .loginIcon {
    font-size: 36px;
  }
}
/* Error message styling */
.errorMessage {
  color: white !important;
  padding: 12px;
  border-radius: 10px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #FF6347;
}

