@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerCredCard {
  flex: 1;
  padding: 35px;
  background-color: #FFFFFF;
  margin: 35px;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerCredCard .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
}

.containerCredCard .subTitle {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

.containerCredCard .containerCard {
  display: flex;
  gap: 30px;
  flex: 1;
  margin-top: 20px;
}

.containerCredCard .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.containerCredCard .containerCard .card .containerInput .title {
  font-size: 21px;
}

.containerCredCard .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 140px;
}

.containerCredCard .containerCard .card .containerInput .inputCredCard {
  width: 100%;
  height: 60px;
  border-radius: 20px;
  background-color: #F3F5F8;
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: 400;
}


.containerCredCard .containerCard .line {
  border: 1px solid #DEE4EF;
}

.containerCredCard .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.containerCredCard .buttonNextCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
}

.containerCredCard .buttonBackCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #FFFFFF;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: 1px solid #77E191;
}

.containerCredCard .buttonBackCRedCard img {
  transform: rotate(-180deg); 
}

@media only screen and (max-width: 842px) {
  .containerCredCard .title {
    font-size: 21px;
  }
  
  .containerCredCard .subTitle {
    font-size: 16px;
  }
  
  .containerCredCard .containerCard {
    gap: 20px;
    flex-direction: column;
  }
  
  .containerCredCard .containerCard .card {
    gap: 20px;
  }
  
  .containerCredCard .containerCard .card .containerInput .title {
    font-size: 18px;
  }
  
  .containerCredCard .containerCard .card .containerInput {
    gap: 10px;
    height: 130px;
  }
  
  .containerCredCard .containerCard .card .containerInput .inputCredCard {
    height: 50px;
    padding: 10px;
    font-size: 18px;
  }
  
  
  .containerCredCard .containerCard .line {
    display: none;
  }
  
  .containerCredCard .containerButtonsSumary {
    justify-content: center;

  }
  
  .containerCredCard .buttonNextCRedCard {
    font-size: 18px;
  }
  
  .containerCredCard .buttonBackCRedCard {
    font-size: 18px;
  }
}