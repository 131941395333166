.contentContainer {
    flex: 1;
    /* overflow-y: auto; */
    -ms-overflow-style: none;
    scrollbar-width: none;
   overflow: auto;
}

.contentContainer::-webkit-scrollbar {
    display: none;
  }