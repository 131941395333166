@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerProfile {
  display: flex;
  gap: 40px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  padding: 35px;
  margin-top: 70px;
  flex: 1;
}

.containerProfile .headerProfile {
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: 320px;
}

.containerProfile .cardProfile {
  background-color: #FFFFFF;
  padding: 35px;
  border-radius: 20px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.containerProfile .containerText {
  display: flex;
  flex-wrap: nowrap;
}

.containerProfile .containerText strong {
  flex-shrink: 0;
  margin-right: 5px;
}

.containerProfile .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.containerProfile .cardProfile .title {
  font-weight: 700;
  font-size: 28px;
  align-self: center;
  margin-bottom: 40px;
  margin-top: 60px;
}

.containerProfile .cardProfile .containerImg {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  border: 12px solid #fff;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerProfile .cardProfile .containerImg .iconUser {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.containerProfile .cardProfile .containerImg .editIcon {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.containerInputsProfile {
  flex: 1;
  background-color: #FFFFFF;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px;
  flex: 3;
}

.containerInputsProfile .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
}

.containerInputsProfile .subTitleInput {
  font-size: 18px;
  font-weight: 400;
  color: #8291A9;
}

.containerInputsProfile .subTitle {
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerInputsProfile .containerCard {
  display: flex;
  gap: 40px;
  flex: 1;
  margin-top: 20px;
}

.containerInputsProfile .containerCard .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.containerInputsProfile .containerCard .card .containerInput .title {
  font-size: 21px;
}

.containerInputsProfile .containerCard .card .containerInput {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 140px;
}

.containerInputsProfile .containerCard .card .containerInput .inputCredCard {
  width: 100%;
  height: 60px;
  border-radius: 20px;
  background-color: #F3F5F8;
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: 400;
}


.containerInputsProfile .containerCard .line {
  border: 1px solid #DEE4EF;
}

.containerProfile .containerButtonsSumary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.containerProfile .buttonDelete {
  text-decoration: underline;
  font-size: 21px;
  font-weight: 400;
  color: #CE3C3C;
  cursor: pointer;
}

.containerProfile .buttonNextCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

.containerProfile .buttonBackCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #FFFFFF;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: 1px solid #77E191;
}

.containerProfile .buttonBackCRedCard img {
  transform: rotate(-180deg);
}


.cardProfile label {
  cursor: pointer;
}

.cardProfile input[type="file"] {
  display: none;
}

.label-bordered {
  border: none;
}

.containerProfile .containerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.containerProfile .containerMenu .menu {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;    
}

.containerProfile .disabledMenus {
  color: rgb(216, 213, 213) !important;
}


.containerProfile .containerMenu .menuSelected {
  background: #FFFFFF;
  border-radius: 20px 20px 0px 0px;
  font-weight: 700;
}

.containerProfile .containerMenu .line {
  width: 2px;
  height: 23px;
  background: #CDD3DD;
}

.containerProfile .containerMenu .none {
  background-color: transparent !important;
}


@media only screen and (max-width: 1920px) {
  .containerProfile {
    gap: 25px;
    padding: 25px;
  }

  .containerProfile .cardProfile {
    background-color: #FFFFFF;
    padding: 25px;
    font-size: 21px;
  }

  .containerProfile .cardProfile .title {
    font-size: 28px;
  }

  .containerInputsProfile .title {
    font-size: 26px;
  }

  .containerInputsProfile .subTitleInput {
    font-size: 16px;
  }

  .containerInputsProfile .subTitle {
    font-size: 19px;
  }

  .containerInputsProfile .containerCard {
    gap: 25px;
  }

  .containerInputsProfile .containerCard .card {
    gap: 25px;
  }

  .containerInputsProfile .containerCard .card .containerInput .title {
    font-size: 19px;
  }

  .containerInputsProfile .containerCard .card .containerInput .inputCredCard {
    font-size: 19px;
  }

  .containerInputsProfile .buttonNextCRedCard {
    font-size: 19px;
  }

  .containerProfile .buttonDelete {
    font-size: 19px;
  }

  .containerInputsProfile .buttonBackCRedCard {
    font-size: 19px;
  }
}

@media only screen and (max-width: 1450px) {

  .containerInputsProfile .containerCard {
    flex-direction: column;
  }

  .containerInputsProfile .containerCard .card .containerInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
  }

}

@media only screen and (max-width: 1094px) {
  .containerProfile {
    flex-direction: column;
  }

  .containerProfile .buttonNextCRedCard {
    align-self: center;
  }

  .containerInputsProfile .subTitle {
    font-size: 18px;
  }

  .containerInputsProfile .subTitle {
    font-size: 18px;
  }

  .containerInputsProfile .containerCard .card .containerInput .title {
    font-size: 18px;
  }

  .containerInputsProfile .containerCard .card .containerInput .inputCredCard {
    font-size: 18px;
  }

  .containerInputsProfile .buttonNextCRedCard {
    font-size: 18px;
  }

  .containerProfile .buttonDelete {
    font-size: 18px;
  }

  .containerInputsProfile .buttonBackCRedCard {
    font-size: 18px;
  }
}

@media only screen and (max-width: 700px) {

  .containerProfile .headerProfile {
    max-width: 500px;
  }
  .containerInputsProfile .containerCard .line {
    display: none;
  }

  .containerProfile .buttonNextCRedCard {
   align-self: flex-end;
  }
  
}