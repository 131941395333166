.containerSacSpan {
  padding: 40px 50px;
  background-color: #FFFFFF;
  margin: 60px;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.containerSacSpan .buttonAddAutoBack {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-size: 21px;
  font-weight: 700 !important;
  cursor: pointer;
  margin-bottom: 10px;
  /* border: 1px solid #77E191;
  padding: 5px 15px;
  border-radius: 10px; */
}


.containerSacSpan .titlePage {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}

.containerSacSpan .containerInputs {
  display: flex;
  flex: 1;
  gap: 40px;
  position: relative;
}

.containerSacSpan .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.custom-timepicker .MuiOutlinedInput-root {
  width: 100%;
    height: 60px;
    border-radius: 20px;
    background-color: #F3F5F8;
    border: none;
    padding: 10px;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: "Outfit", sans-serif;
    color: #102649;
}

.custom-timepicker .MuiOutlinedInput-notchedOutline {
  border: none;
}

.containerSacSpan .containerInput {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.containerSacSpan .containerInput .title {
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 700;
}

.containerSacSpan .containerInput .inputStyle {
  width: 100%;
  height: 60px;
  border-radius: 20px;
  background-color: #F3F5F8;
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: 400;
  cursor: pointer;
  resize: none;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerSacSpan .containerButtons {
  display: flex;
  gap: 20px;
 justify-content: flex-end;
  width: 100%;
}

.containerSacSpan .containerButtons .buttonSimulator,
.containerSacSpan .containerButtons .buttonSave,
.containerSacSpan .containerButtons .buttonReset {
    font-size: 21px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    gap: 10px;
    cursor: pointer;
    width: 240px;
    height: 56px;
}

.containerSacSpan .containerButtons .buttonSave {
  background-color: #77E191;
}

.containerINputsPhones {
  display: flex;
  gap: 10px;
  align-items: center;
}

.containerINputsPhones button {
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  gap: 10px;
  cursor: pointer;
  padding: 0px 20px;
  border: none;
  cursor: pointer;
  background-color: #77E191;
}

.containerINputsPhones label {
  font-size: 21px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  gap: 10px;
  cursor: pointer;
  padding: 0px 20px;
  border: none;
  cursor: pointer;
  background-color: #77E191;
}

.containerSacSpan .containerNUmbers {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 5px;
}

.containerSacSpan .containerNUmbers div {
  display: flex;
  background-color: #F3F5F8;
  gap: 5px;
  padding: 10px 10px;
  align-items: center;
  border-radius: 10px;
}

.containerSacSpan .containerNUmbers button {
  background-color: red;
  border: none;
  cursor: pointer;
  color: #F3F5F8;
  border-radius: 2px;
  width: 20px;
  height: 20px;
}

@media only screen and (max-width: 1153px) {
    .containerSacSpan {
      padding: 30px;
      margin: 20px;
    }

  .containerSacSpan .containerInputs {
    flex-direction: column;
  }

  .containerSacSpan .containerButtons .buttonSimulator,
  .containerSacSpan .containerButtons .buttonSave,
  .containerSacSpan .containerButtons .buttonReset {
      font-size: 12px;
      width: 140px;
      height: 40px;
  }

  .containerSacSpan .containerButtons .buttonSave img {
      width: 8px;
  }

  .containerSacSpan .titlePage {
      display: block;
      font-size: 24px;
      font-weight: 700;

  }

  .containerSacSpan .containerButtons {
      /* align-self: center; */
  }

  .containerSacSpan .containerInput .title {
    font-size: 18px;
  }
  .custom-timepicker .MuiOutlinedInput-root {
      font-size: 18px !important;
  }

  .containerSacSpan .containerInput .inputStyle {
    font-size: 18px;
  }
  
  
  .containerSacSpan .containerInput .subTitleInput {
    font-size: 12px;
}
}