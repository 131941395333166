.containerCheckProfile {
  padding: 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.containerHeader {
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 28px;
  font-weight: 700;
  color: #102649;
  font-family: "Outfit", sans-serif;
}

.text {
  font-size: 24px;
  font-weight: 400;
  color: #102649;
  font-family: "Outfit", sans-serif;
}

.urlForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.optionSelection {
  display: flex;
  gap: 20px;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 18px;
  color: #102649;
}

.custom-radio input {
  display: none;
}

.radio-btn {
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 2px solid #77e191;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
}

.custom-radio input:checked ~ .radio-btn::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: #77e191;
  border-radius: 50%;
}

.urlInput,
.descriptionInput {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #77e191;
  border-radius: 8px;
}

.descriptionInput {
  resize: vertical;
}

.submitButton {
  background-color: #77e191;
  color: #102649;
  font-size: 21px;
  font-weight: 700;
  border: none;
  border-radius: 16px;
  height: 56px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submitButton:disabled {
  background-color: #a5aebd;
  cursor: not-allowed;
}

.progressScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #77e191;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.errorMessage {
  color: darkred;
  font-size: 14px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}