@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAutoMensagens {
  padding: 30px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  display: flex;
  flex-direction: column;
}

.containerAutoMensagens .containerInputsClients {
  min-height: 45px;
}


.containerAutoMensagens .containerInputsClients .inputSearch {
  width: 100%;
  height: 35px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  font-size: 18px; 
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #dddddd;
}


.containerAutoMensagens .cardClients {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 60vh;
  overflow: auto;
}

.containerAutoMensagens .cardClients .header,
.containerAutoMensagens .cardClients .containerItens .client {
  display: flex;
  width: 100%;
  height: 85px;
}

.containerAutoMensagens .cardClients .header div,
.containerAutoMensagens .cardClients .containerItens .client div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  text-align: center;
  flex: 1;
}

.containerAutoMensagens .cardClients .containerItens .client div {
  padding: 5px;
}

.containerAutoMensagens .cardClients .header div {
  font-size: 24px;
  font-weight: 700;
}

.containerAutoMensagens .cardClients .header .containerIconFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.containerAutoMensagens .cardClients .header .containerIconFilter img {
  cursor: pointer;
  width: 25px;
}

.containerAutoMensagens .cardClients .header .containerIconFilter button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #a62527;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  border-radius: 2px;
  font-size: 18px;
}

.containerAutoMensagens .cardClients .containerItens .client div {
  font-size: 21px;
  font-weight: 400;
}

.containerAutoMensagens .cardClients .containerItens .buttonMoreCheck {
  max-width: 160px;
  border-radius: 16px;
  background-color: #77E191;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 700 !important;
  cursor: pointer;
}

.containerAutoMensagens .buttonAddAuto {
  border-radius: 16px;
  background-color: #77E191;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700 !important;
  cursor: pointer;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.containerAutoMensagens .cardClients .containerItens .containerName {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerAutoMensagens .cardClients .containerItens .containerName .userIMage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}


.containerAutoMensagens .containerPagination {
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  font-weight: 400;
  margin-top: 20px;
}

.containerAutoMensagens .containerPagination .pagination {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 400px;
  justify-content: space-between;
}

.containerAutoMensagens .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #102649;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.containerAutoMensagens .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #102649;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}

.containerAutoMensagens .pagination .containerButton:hover {
  opacity: 55%;
}

.containerAutoMensagens .pagination .containerButton .left {
  transform: rotate(-180deg);
}

.containerAutoMensagens .containerPagination .totalPage {
  display: flex;
  gap: 10px;
}

.containerAutoMensagens .containerPagination .totalPage .text {
  font-size: 21px;
  font-weight: 700;
}

@media only screen and (max-width: 1920px) {
  .containerAutoMensagens {
    padding: 0px 50px;
  }

  .containerAutoMensagens .containerInputsClients {
    min-height: 45px;
  }

  .containerAutoMensagens .containerInputsClients .inputSearch {
    height: 35px;
    font-size: 16px;
  }


  .containerAutoMensagens .cardClients .header div {
    font-size: 21px;
  }


  .containerAutoMensagens .cardClients .header .containerIconFilter img {
    width: 20px;
  }

  .containerAutoMensagens .cardClients .header .containerIconFilter button {
    width: 20px;
    height: 20px;
    font-size: 16px;
  }

  .containerAutoMensagens .cardClients .containerItens .client div {
    font-size: 18px;
  }

  .containerAutoMensagens .cardClients .containerItens .buttonMoreCheck {
    width: 100%;
    height: 40px;
    font-size: 18px;
  }

  .containerAutoMensagens .cardClients .containerItens .containerName .userIMage {
    width: 30px;
    height: 30px;
  }

  .containerAutoMensagens .containerPagination {
    font-size: 16px;
  }

  .containerAutoMensagens .containerPagination .pagination {
    gap: 15px;
  }

  .containerAutoMensagens .pagination .containerButton {
    font-size: 16px;
  }


  .containerAutoMensagens .containerPagination .totalPage .text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1437px) {
  .containerAutoMensagens {
    padding: 5px 20px;
  }

  .containerAutoMensagens .cardClients .header div,
  .containerAutoMensagens .cardClients .containerItens .client div {
    flex: none;
    width: 200px;
  }

  .containerAutoMensagens .containerInputsClients {
    min-height: 45px;
  }

  .containerAutoMensagens .containerInputsClients .inputSearch {
    height: 35px;
    font-size: 14px;
  }


  .containerAutoMensagens .cardClients .header div {
    font-size: 18px;
  }


  .containerAutoMensagens .cardClients .containerItens .client div {
    font-size: 16px;
  }

  .containerAutoMensagens .cardClients .containerItens .buttonMoreCheck {
    height: 35px;
    font-size: 16px;
  }
}


@media only screen and (max-width: 720px) {
  .containerAutoMensagens {
    padding: 20px;
  }

  .containerAutoMensagens .containerPagination {
    font-size: 14px;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }

  .containerAutoMensagens .pagination .containerButton {
    font-size: 14px;
  }

  .containerAutoMensagens .containerPagination .pagination {
    gap: 10px;
    width: 90vw;
  }

  .containerAutoMensagens .containerPagination .totalPage .text {
    font-size: 16px;
  }

  .containerAutoMensagens .containerPagination .totalPage {
    justify-content: flex-end;
  }
}