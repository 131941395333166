@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  /* Colors */
  --primary-color: #102649;
  --white: #FFFFFF;
  --success-color: #77E191;
  --error-color: #a62527;
  --border-color: #DEE4EF;
  
  /* Layout */
  --padding-lg: 60px;
  --padding-md: 30px;
  --padding-sm: 20px;
  --padding-xs: 10px;
  
  /* Borders */
  --border-radius: 20px;
  --border-radius-sm: 10px;
  
  /* Typography */
  --font-family: "Outfit", sans-serif;
  --font-lg: 24px;
  --font-md: 21px;
  --font-sm: 16px;
  --font-xs: 12px;
  /* Other */
  --transition: all 0.2s ease;
}

/* Main Container */
.containerClients {
  padding: var(--padding-lg);
  font-family: var(--font-family);
  color: var(--primary-color);
}

/* Search Input */
.containerInputsClients .inputSearch {
  width: 100%;
  height: 45px;
  font-size: var(--font-sm);
  padding: var(--padding-xs);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-sm);
  transition: var(--transition);
}

/* Client Cards */
.cardClients {
  display: flex;
  flex-direction: column;
  gap: var(--padding-sm);
  min-height: 60vh;
  padding: var(--padding-sm) 0;
  overflow: auto;
  margin-bottom: var(--padding-lg);
}

/* Header and Items */
.cardClients .header {
  display: flex;
  width: 100%;
  padding: var(--padding-xs);
}

.cardClients .client {
  display: flex;
  width: 100%;
  height: 85px;
  padding: var(--padding-xs);
  border-radius: var(--border-radius);
  background: var(--white);
  margin-bottom: var(--padding-xs);
  cursor: pointer;
  transition: var(--transition);
}

.cardClients .client:hover {
  transform: translateX(5px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  background-color: var(--white);
}

.cardClients .header div,
.cardClients .client div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Filter Button */
.containerIconFilter button {
  height: 35px;
  width: 35px;
  background-color: var(--error-color);
  color: var(--white);
  border: none;
  border-radius: var(--border-radius-sm);
  font-weight: 700;
}

/* Action Button */
.buttonMoreCheck {
  max-width: 160px;
  height: 45px;
  background-color: var(--success-color);
  border-radius: var(--border-radius);
  font-weight: 700;
}

/* User Image */
.userIMage {
  width: 40px;
  height: 40px;
  border-radius: var(--border-radius);
  object-fit: cover;
}

/* Pagination */
.containerPagination {
  display: flex;
  justify-content: space-between;
  margin-top: var(--padding-md);
}

.containerButton {
  padding: var(--padding-xs) var(--padding-sm);
  background-color: var(--white);
  border: none;
  border-radius: var(--border-radius);
  font-weight: 700;
}

/* Hover States */
.containerButton:hover,
.containerIconFilter img:hover,
.containerIconFilter button:hover,
.buttonMoreCheck:hover {
  opacity: 0.8;
}

/* Responsive */
@media (max-width: 1920px) {
  :root {
    --padding-lg: 50px;
    --padding-md: 25px;
    --font-lg: 21px;
    --font-md: 18px;
  }
}

@media (max-width: 1437px) {
  :root {
    --padding-lg: 30px;
    --padding-md: 20px;
    --font-lg: 18px;
    --font-md: 16px;
  }
  
  .cardClients .header div,
  .cardClients .client div {
    flex: none;
    width: 200px;
  }
}

@media (max-width: 720px) {
  :root {
    --padding-lg: 20px;
    --padding-md: 15px;
  }
  
  .containerPagination {
    flex-direction: column;
    align-items: center;
    gap: var(--padding-sm);
  }
}