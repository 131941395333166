@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAll {
    position: relative;
    background-color: #F3F5F8;
    height: 100vh;
}

.containerAll .ondas {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    /* Ajuste conforme necessário */
    height: 100%;
    /* Ajuste conforme necessário */
    object-fit: cover;
    /* Ajuste conforme necessário */
    z-index: 1;
}

.main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    overflow: hidden;
    z-index: 3;
}

.footerApp {
    font-family: "Outfit", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.68px;
    text-align: center;
    padding: 10px 0;
    color: #102649;
}

.mobile {
    display: none;
}

.containerHeaderMobile {
    display: none;
}

@media only screen and (max-width: 900px) {
    .containerHeaderMobile {
        background-color: #0C2040;
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
    }

    .footerApp {
        font-family: "Outfit", sans-serif;
        font-size: 13px;
        color: #FFFFFF;
        background-color: #102649;
        margin-top: 20px;
    }

    .web {
        display: none;
    }

    .mobile {
        display: block;
        margin-top: 30px;
    }
}