@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerAttendant {
    padding: 60px;
    font-family: "Outfit", sans-serif;
    color: #102649;
}

.containerInfoSimulator {
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 20px 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;
    color: red;
}

.containerInfoSimulator strong {
    font-size: 18px;
}

.containerAttendant .titlePage {
    display: none;
}

.containerAttendant .contentMobile {
    display: none;
}

.containerAttendant .containerMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.containerAttendant .containerMenu .menu {
    flex: 1;
    text-align: center;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.containerAttendant .disabledMenus {
    color: rgb(216, 213, 213) !important;
}


.containerAttendant .containerMenu .menuSelected {
    background: #FFFFFF;
    border-radius: 20px 20px 0px 0px;
    font-weight: 700;
}

.containerAttendant .containerMenu .line {
    width: 2px;
    height: 23px;
    background: #CDD3DD;
}

.containerAttendant .containerMenu .none {
    background-color: transparent !important;
}


.containerAttendant .containerInput {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.containerAttendant .containerInput .title {
    display: flex;
    align-items: center;
    font-size: 21px;
    font-weight: 700;
}

.containerAttendant .containerInput .subTitleInput {
    font-size: 16px;
    font-weight: 400;
    color: #8291A9;
    margin-bottom: 15px;
}

.containerAttendant .containerInput .inputStyle {
    width: 100%;
    height: 60px;
    border-radius: 20px;
    background-color: #F3F5F8;
    border: none;
    padding: 10px;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: "Outfit", sans-serif;
    color: #102649;
}

.containerAttendant .containerButtons {
    display: flex;
    gap: 20px;
    align-self: flex-end;
}

.containerAttendant .containerButtons .buttonSimulator,
.containerAttendant .containerButtons .buttonSave,
.containerAttendant .containerButtons .buttonReset {
    font-size: 21px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    gap: 10px;
    cursor: pointer;
    width: 240px;
    height: 56px;
}

.containerAttendant .containerButtons .buttonSimulator {
    border: 2px solid #77E191;
    background-color: #FFFFFF;
}

.containerAttendant .containerButtons .buttonSave {
    background-color: #77E191;
}

.containerAttendant .containerButtons .buttonReset {
    background-color: #CE3C3C;
    color: #FFFFFF;
}

@media only screen and (max-width: 1500px) {
    .containerAttendant {
        padding: 20px;
    }

    .containerAttendant .containerMenu .menu {
        font-size: 16px;
    }

    /*     
    .containerAttendant .containerMenu .line {
        width: 2px;
        height: 23px;
        background: #CDD3DD;
    } */


    .containerAttendant .containerInput .title {
        font-size: 19px;
    }

    .containerAttendant .containerInput .subTitleInput {
        font-size: 14px;
    }

    .containerAttendant .containerInput .inputStyle {
        height: 50px;
        font-size: 19px;
    }


    .containerAttendant .containerButtons .buttonSimulator,
    .containerAttendant .containerButtons .buttonSave,
    .containerAttendant .containerButtons .buttonReset {
        font-size: 19px;
        width: 200px;
        height: 46px;
    }

}

@media only screen and (max-width: 1431px) {

    .containerAttendant .containerMenu .menu {
        font-size: 14px;
    }


    .containerAttendant .containerInput .title {
        font-size: 17px;
    }

    .containerAttendant .containerInput .subTitleInput {
        font-size: 12px;
    }

    .containerAttendant .containerInput .inputStyle {
        height: 40px;
        font-size: 17px;
    }


    .containerAttendant .containerButtons .buttonSimulator,
    .containerAttendant .containerButtons .buttonSave,
    .containerAttendant .containerButtons .buttonReset {
        font-size: 17px;
        width: 180px;
        height: 40px;
    }
}

@media only screen and (max-width: 1153px) {
    .containerAttendant .contentWeb {
        display: none;
    }

    .containerAttendant .contentMobile {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .containerAttendant .containerMenu {
        display: none;
    }

    .containerAttendant .containerButtons .buttonSimulator,
    .containerAttendant .containerButtons .buttonSave,
    .containerAttendant .containerButtons .buttonReset {
        font-size: 12px;
        width: 140px;
        height: 40px;
    }

    .containerAttendant .containerButtons .buttonSave img {
        width: 8px;
    }

    .containerAttendant .titlePage {
        display: block;
        font-size: 24px;
        font-weight: 700;

    }

    .containerAttendant .containerButtons {
        align-self: center;
    }
}
