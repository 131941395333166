.containerStep7 {
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.containerStep7 .containerInputs {
  display: flex;
  flex: 1;
  gap: 30px;
  position: relative;
}

.containerStep7 .containerInputs .card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-timepicker .MuiOutlinedInput-root {
  width: 100%;
    height: 60px;
    border-radius: 20px;
    background-color: #F3F5F8;
    border: none;
    padding: 10px;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: "Outfit", sans-serif;
    color: #102649;
}

.custom-timepicker .MuiOutlinedInput-notchedOutline {
  border: none;
}

@media only screen and (max-width: 1153px) {

}
