@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --primary-bg: #102649;
  --secondary-bg: #051227;
  --line-color: #06152c;
  --white: #FFFFFF;
  --submenu-bg: #1a3f75;
  --submenu-hover: #2a4f85;
  --success-color: #77E191;
  --error-color: #CE3C3C;
  --icon-gray: #D9D9D9;
  --font-family: "Outfit", sans-serif;
  --transition-speed: 0.5s;
}

/* Base layout */
.sidebar {
  background-color: var(--primary-bg);
  width: 250px;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

/* Logo section */
.logoSideBar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-bg);
  width: 100%;
  height: 100px;
}

.lineMenu {
  width: 80%;
  height: 2px;
  background-color: var(--line-color);
  margin: 0 45px;
}

/* Menu items */
.containerMenuLink {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 5px 40px;
  padding: 10px 15px;
}

.containerMenuLink .textMenu {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.68;
  color: var(--white);
}

.containerMenuLink .imgMenu {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
}

/* Selected menu state */
.selectedMenu {
  background-color: var(--secondary-bg);
  border-radius: 20px;
}

.selectedMenu .textMenu {
  font-weight: 700;
}

/* Exit menu */
.menuSair {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--secondary-bg);
  width: 100%;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Mobile menu button */
.menu-icon {
  display: none;
  position: absolute;
  width: 40px;
  right: 10px;
  top: 0;
}

/* Hamburger button */
.btn {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 15px;
  left: 0;
  cursor: pointer;
}

.btn-left,
.btn-right {
  position: absolute;
  width: 15px;
  height: 8px;
  top: 25px;
  transition: var(--transition-speed);
  background-color: var(--icon-gray);
}

.btn-left { left: 0; }
.btn-right { left: 15px; }

.btn-left::before,
.btn-left::after,
.btn-right::before,
.btn-right::after {
  content: "";
  position: absolute;
  width: 15px;
  height: 8px;
  background-color: var(--icon-gray);
  transition: var(--transition-speed);
}

.btn-left::before,
.btn-right::before { top: -12px; }
.btn-left::after,
.btn-right::after { top: 12px; }

/* Active button states */
.active .btn-left,
.active .btn-right {
  background: transparent !important;
}

.active .btn-left::before {
  transform: rotate(45deg) scaleX(1.4) translate(4px, 4px);
}

.active .btn-left::after {
  transform: rotate(-45deg) scaleX(1.4) translate(4px, -4px);
}

.active .btn-right::before {
  transform: rotate(-45deg) scaleX(1.4) translate(-4px, 4px);
}

.active .btn-right::after {
  transform: rotate(45deg) scaleX(1.4) translate(-4px, -4px);
}

/* Submenu styles */
.subMenuContainer {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subMenuLink .textMenu {
  font-size: 16px;
  color: var(--white) !important;
}

.containerMenuLink.subMenuLink {
  padding: 10px;
  background-color: var(--submenu-bg);
  border-radius: 10px;
  margin: 0 20px 0 0;
}

.containerMenuLink.subMenuLink:hover {
  background-color: var(--submenu-hover);
}

.expandIcon {
  width: 20px;
  height: 20px;
}

/* Mobile styles */
.containerStatusCheckMobiles {
  display: none;
}

@media (max-width: 900px) {
  .sidebar {
    width: 280px;
    position: absolute;
    right: 0;
    z-index: 4;
  }

  .containerSideBar {
    display: flex;
  }

  .containerSideBar .blur {
    background-color: rgba(16, 38, 73, 0.5);
    width: 880px;
    height: 100%;
    position: absolute;
    backdrop-filter: blur(5px);
    z-index: 4;
  }

  .logoSideBar {
    background-color: var(--primary-bg);
  }

  .logoSideBar img,
  .containerStatusCheckMobiles { display: none; }

  .menu-icon { display: flex; }

  .containerStatusCheckMobiles {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    font-family: var(--font-family);
    color: var(--white);
  }

  .containerStatusCheckMobiles .true,
  .containerStatusCheckMobiles .false {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 26px;
    margin: 5px 30px;
    padding: 15px;
    max-width: 150px;
    border: 1px solid;
    border-radius: 12px;
    background-color: transparent;
  }

  .containerStatusCheckMobiles .true {
    border-color: var(--success-color);
  }

  .containerStatusCheckMobiles .false {
    border-color: var(--error-color);
  }

  .containerStatusCheckMobiles .cicle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }

  .containerStatusCheckMobiles .true .cicle {
    background-color: var(--success-color);
  }

  .containerStatusCheckMobiles .false .cicle {
    background-color: var(--error-color);
  }

  .containerMenuLink {
    margin: 10px 20px;
    padding: 10px;
  }

  .containerMenuLink .textMenu,
  .menuSair {
    font-size: 21px;
  }

  .lineMenu {
    margin: 15px;
  }
}