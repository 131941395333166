@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Common styles */
:root {
  --primary-color: #102649;
  --font-family: "Outfit", sans-serif;
  --success-bg: #E2EEE5;
  --success-dot: #77E191;
  --error-bg: #F4E6E6;
  --error-dot: #CE3C3C;
}

/* Base layout */
.topbar {
  width: 100%;
  height: 70px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

/* Title section */
.containerTitlePage {
  display: flex;
  gap: 15px;
  align-items: center;
  margin: 0 15px;
}

.containerTitlePage .titleTop {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.26;
  color: var(--primary-color);
}

/* Right section */
.containerRigthTop {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 15px;
}

.containerRigthTop .imgTOp {
  width: 22px;
}

.containerRigthTop .userIMage {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.containerRigthTop .nameText {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}

.containerRigthTop .subText {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

/* Status indicators */
.containerStatusCheck {
  display: flex;
  gap: 20px;
  margin: 0 10px;
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-family);
  color: var(--primary-color);
}

.containerStatusCheck .true,
.containerStatusCheck .false {
  padding: 4px 12px;
  gap: 10px;
  border-radius: 12px;
  display: flex;
  align-items: center;
}

.containerStatusCheck .true {
  background-color: var(--success-bg);
}

.containerStatusCheck .false {
  background-color: var(--error-bg);
}

.containerStatusCheck .cicle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.containerStatusCheck .true .cicle {
  background-color: var(--success-dot);
}

.containerStatusCheck .false .cicle {
  background-color: var(--error-dot);
}

/* Notification icon */
.iconNotifica {
  display: flex;
  position: absolute;
  border: 1px solid orangered;
  background-color: orangered;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

/* Responsive design */
@media only screen and (max-width: 900px) {
  .topbar {
    gap: 15px;
  }

  .containerTitlePage {
    gap: 5px;
  }

  .containerTitlePage .titleTop {
    font-size: 24px;
    line-height: 20px;
  }

  .containerRigthTop {
    gap: 10px;
  }

  .containerRigthTop .imgTOp {
    width: 24px;
  }

  .containerRigthTop .userIMage {
    width: 40px;
    height: 40px;
  }

  .containerRigthTop .nameText,
  .containerRigthTop .subText,
  .containerStatusCheck {
    display: none;
  }

  .iconNotifica {
    width: 20px;
    height: 20px;
  }
}