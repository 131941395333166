.containerStep1Auto {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
}

.containerStep1Auto .title {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    text-align: center;
    color: #102649;
    margin-bottom: 27px;
}

.containerStep1Auto img {
    width: 278px;
    height: 26px;
}

.titlePageAuto {
align-self: flex-start;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  margin-left: 40px;
}

.buttonNextAUto {
  width: 100%;
  padding: 15px;
  border-radius: 16px;
  background: #77E191;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-family: Outfit;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.68px;
  text-align: left;
  color: #102649;
  border: none;
  cursor: pointer;
}

.containerStep1Auto .cardContent {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;
    padding: 37px 34px 37px 34px;
    gap: 34px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px #CDDCE433;
    font-family: Outfit;
    font-size: 19px;
    font-weight: 400;
    line-height: 23.94px;
    text-align: left;
    color: #102649;

}

.containerStep1Auto .cardContent .containerInputPhone {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.containerStep1Auto .cardContent .containerInputPhone div {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: left;
    color: #8291A9;

}


.containerStep1Auto .cardContent .input {
    width: 100%;
    height: 60px;
    top: 36px;
    gap: 0px;
    border-radius: 20px;
    background: #F3F5F8;
    border: none;
    cursor: pointer;
    padding: 0px 20px;
}

.containerStep1Auto .cardContent button {
    width: 85%;
    height: 56px;
    border-radius: 16px;
    background: #77E191;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border: none;
    cursor: pointer;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    color: #102649;
    align-self: center;
}

.containerStep1Auto .cardContent button img {
    width: 25.94px;
    height: 22.75px;
}

.containerStep1Auto .cardContent .buttonBackCheck {
    width: 85%;
    height: 56px;
    border-radius: 16px;
    background: #ffffff !important;
    border: 2px solid #77E191 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    cursor: pointer;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.68px;
    text-align: left;
    color: #102649;
}

.containerStep1Auto .cardContent .nextButton {
    font-family: Outfit;
    font-size: 21px;
    font-weight: 400;
    line-height: 26.46px;
    color: #798DA4;
    align-self: center;
    text-decoration: underline;
    cursor: pointer;
}



.parametersContainer {
    /* padding: 20px; */
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    /* background-color: #f9f9f9; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    /* max-width: 600px; */
    /* margin: 0 auto; */
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  
  .parametersTitle {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }
  
  .parametersSubtitle {
    font-size: 16px;
    font-weight: 400;
    color: #8291A9;
  }
  
  .parametersInputsWrapper {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    gap: 16px;
  }
  
  .inputGroupWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1 1 calc(50% - 16px);
  }
  
  .inputField {
    flex: 1;
    padding: 8px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    background-color: #F3F5F8;
    font-size: 21px;
    font-weight: 400;
    cursor: pointer;
    resize: none;
    font-family: "Outfit", sans-serif;
    color: #102649;
  }
  
  .inputField:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .addParameterButton {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .addParameterButton:hover {
    background-color: #0056b3;
  }
  