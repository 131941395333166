@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --primary-color: #102649;
  --white: #FFFFFF;
  --success-color: #77E191;
  --bg-light: #ECEFF4;
  --border-radius: 20px;
  --font-family: "Outfit", sans-serif;
  --graph-height: 350px;
  --bar-width: 50px;
  --transition-speed: 0.3s;
}

/* Graph Container */
.containerDashboard .leads-graph {
  text-align: center;
  font-family: var(--font-family);
  padding: 20px;
  margin-top: 5px;
  background: var(--white);
  border-radius: var(--border-radius);
}

/* Header */
.containerDashboard .leads-graph .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.containerDashboard .leads-graph .header .text {
  font-family: var(--font-family);
  text-align: left;
  font-size: 21px;
  font-weight: 400;
  color: var(--primary-color);
}

/* Graph Structure */
.containerDashboard .graph-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: var(--graph-height);
  margin-top: 15px;
  overflow: hidden;
}

.containerDashboard .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
}

.containerDashboard .fill {
  background-color: var(--bg-light);
  width: calc(var(--bar-width) - 16px);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border-radius: var(--border-radius);
  height: 100%;
  transition: height var(--transition-speed) ease;
}

.containerDashboard .leads {
  background-color: var(--success-color);
  width: var(--bar-width);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 5px 0;
  border-radius: var(--border-radius);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-color);
  transition: height var(--transition-speed) ease;
}

.containerDashboard .label {
  margin-top: 8px;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 400;
}

/* Responsive Design */
@media only screen and (max-width: 1920px) {
  .containerDashboard .leads-graph .header .text {
    font-size: 18px;
  }
  
  .containerDashboard .leads {
    font-size: 13px;
    width: calc(var(--bar-width) - 5px);
  }
  
  .containerDashboard .fill {
    width: calc(var(--bar-width) - 21px);
  }
  
  .containerDashboard .label {
    font-size: 15px;
  }
}

@media only screen and (max-width: 883px) {
  .containerDashboard .leads-graph {
    padding: 15px;
    margin-top: 20px;
  }
  
  .containerDashboard .leads-graph .header .text {
    font-size: 16px;
  }
  
  .containerDashboard .leads {
    font-size: 12px;
    width: calc(var(--bar-width) - 10px);
  }
  
  .containerDashboard .fill {
    width: calc(var(--bar-width) - 26px);
  }
  
  .containerDashboard .label {
    font-size: 12px;
  }
}