@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerChatHistorySales {
    display: flex;
    gap: 20px;
    font-family: "Outfit", sans-serif;
    color: #102649;
    padding: 0px;
    flex: 1;
}

.containerChatHistorySales .headerChatHistory {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
}

.containerChatHistorySales .cardChatHistory {
    background-color: #FFFFFF;
    padding: 35px;
    border-radius: 20px;
    font-size: 21px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
}

.containerChatHistorySales .containerText {
    display: flex;
    flex-wrap: nowrap;
}

.containerChatHistorySales .containerText strong {
    flex-shrink: 0;
    margin-right: 5px;
}

.containerChatHistorySales .containerText span {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.containerChatHistorySales .cardChatHistory .title {
    font-weight: 700;
    font-size: 28px;
    align-self:flex-start;
    margin-bottom: 15px;
    /* margin-top: 60px; */
}

.containerChatHistorySales .cardChatHistory .containerImg {
    position: absolute;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid #fff;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerChatHistorySales .cardChatHistory .containerImg .iconUser {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.containerChatHistorySales .cardScore {
    background-color: #FFFFFF;
    padding: 35px;
    border-radius: 20px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.containerChatHistorySales .cardScore img {
    width: 41px;
    height: 41px;
}

.containerChatHistorySales .cardScore .containerText {
    display: flex;
    flex-direction: column;
}


.containerChatHistorySales .cardScore .containerText .title {
    font-weight: 400;
    font-size: 21px;
}


.containerChatHistorySales .cardScore .containerText .subtitle {
    font-weight: 700;
    font-size: 24px;
}

.containerSumarySales  {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 3;
}

.containerSumarySales  .containerBox {
    background-color: #FFFFFF;
    padding: 35px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.containerSumarySales  .containerBox .title {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 700;
    font-size: 24px;
}

.containerSumarySales  .containerBox img {
    width: 17px;
    height: 17px;
}

.containerSumarySales  .containerBox .text {
    font-weight: 400;
    font-size: 18px;
}

.containerSumarySales  .containerBox .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
}

.containerSumarySales  .containerBox .containerHeader .containerIcons {
    display: flex;
    gap: 10px;
}

.containerSumarySales  .containerBox .containerHeader .containerIconFilter img {
    cursor: pointer;
    width: 25px;
}

.containerSumarySales  .containerBox .containerHeader .containerIconFilter button {
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: #a62527;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 700;
    border-radius: 2px;
    font-size: 18px;
}


.containerSumarySales  .containerBox .containerHeader .containerIconFilter img {
    width: 20px;
    height: 20px;
}

.containerSumarySales  .containerBox .containerHeader .containerIconFilter button {
    width: 20px;
    height: 20px;
    font-size: 16px;
}

.containerSumarySales  .containerBox .inputSearch {
    width: 100%;
    height: 35px;
    font-family: "Outfit", sans-serif;
    color: #102649;
    font-size: 18px;
    padding: 10px;
    border-radius: 2px;
    border: 1px solid #dddddd;
}


@media only screen and (max-width: 1920px) {

    .containerChatHistorySales {
        padding: 0px;
    }

    .containerChatHistorySales .cardChatHistory {

        padding: 20px;
        font-size: 18px;
    }

    .containerChatHistorySales .cardChatHistory .title {
        font-size: 21px;
    }

    .containerChatHistorySales .cardScore .containerText .title {
        font-size: 18px;
    }


    .containerChatHistorySales .cardScore .containerText .subtitle {
        font-size: 18px;
    }

    .containerSumarySales  .containerBox {
        padding: 20px;
    }


    .containerSumarySales  .containerBox .title {
        font-size: 20px;
    }


    .containerSumarySales  .containerBox .text {
        font-size: 16px;
    }

    .containerSumarySales  .containerBox .containerHeader .containerIconFilter button {
        font-size: 16px;
    }

    .containerSumarySales  .containerBox .containerHeader .containerIconFilter button {
        font-size: 14px;
    }

    .containerSumarySales  .containerBox .inputSearch {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1450px) {
    .containerChatHistorySales {
        flex-direction: column;
    }

    .containerChatHistorySales .headerChatHistory {
        max-width: 100%;
    }
}

@media only screen and (max-width: 1094px) {

    .containerChatHistorySales .cardChatHistory {
        font-size: 16px;
    }

    .containerChatHistorySales .cardChatHistory .title {
        font-size: 18px;
    }

    .containerChatHistorySales .cardScore .containerText .title {
        font-size: 16px;
    }


    .containerChatHistorySales .cardScore .containerText .subtitle {
        font-size: 16px;
    }

    .containerSumarySales  .containerBox {
        padding: 18px;
    }


    .containerSumarySales  .containerBox .title {
        font-size: 18px;
    }


    .containerSumarySales  .containerBox .text {
        font-size: 14px;
    }

    .containerSumarySales  .containerBox .containerHeader .containerIconFilter button {
        font-size: 14px;
    }

    .containerSumarySales  .containerBox .containerHeader .containerIconFilter button {
        font-size: 14px;
    }

    .containerSumarySales  .containerBox .inputSearch {
        font-size: 14px;
    }

    .containerChatHistorySales .cardChatHistory .containerImg {
        width: 140px;
        height: 140px;
    }
}