@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

:root {
  --primary-color: #102649;
  --border-color: #bec6d5;
  --white: #FFFFFF;
  --bg-hover: white;
  --font-family: "Outfit", sans-serif;
  --border-radius: 20px;
  --transition-speed: 0.2s;
}

.containerCardLeads {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: var(--font-family);
  color: var(--primary-color);
  font-weight: 400;
  font-size: 16px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Hide scrollbar */
.containerCardLeads::-webkit-scrollbar {
  display: none;
}

.containerCardLeads .card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 70px;
  width: 100%;
  padding: 20px;
  border-radius: var(--border-radius);
  cursor: pointer;
  background-color: var(--white);
  transition: all var(--transition-speed) ease;
}

/* Added hover effect */
.containerCardLeads .card:hover {
  background-color: var(--bg-hover);
  transform: translateX(5px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.containerCardLeads .card .containerUser {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
  height: 100%;
}

.containerCardLeads .card .containerUser .text {
  font-weight: 600;
  font-size: 14px;
}

.containerCardLeads .card .containerUser .lines {
  height: 100%;
  width: 1px;
  background-color: var(--border-color);
}

.containerCardLeads .card .arrow {
  justify-self: end;
  transition: transform var(--transition-speed) ease;
}

/* Added arrow animation on hover */
.containerCardLeads .card:hover .arrow {
  transform: translateX(3px);
}

/* Responsive styles */
@media only screen and (max-width: 1920px) {
  .containerCardLeads {
    font-size: 15px;
  }
  
  .containerCardLeads .card .containerUser .text {
    font-size: 16px;
  }
}

@media only screen and (max-width: 883px) {
  .containerCardLeads {
    font-size: 16px;
    overflow: visible;
  }
  
  .containerCardLeads .card {
    height: 60px;
    padding: 15px;
  }
  
  .containerCardLeads .card .containerUser .text {
    font-size: 12px;
  }
  
  .containerCardLeads .card .containerUser {
    gap: 10px;
  }
}