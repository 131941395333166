@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerClients {
  padding: 30px;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerClients .containerInputsClients {
  min-height: 45px;
}


.containerClients .containerInputsClients .inputSearch {
  width: 100%;
  height: 35px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  font-size: 18px;
  padding: 10px;
  border-radius: 2px;
  /* border: 1px solid #dddddd; */
}


.containerClients .cardClients {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 60vh;
  overflow-y: scroll; /* Barra de rolagem no eixo Y */
  padding-top: 20px;  /* Espaço no topo */
  padding-bottom: 20px; /* Espaço embaixo */
  position: relative;
}

/* Adicionando uma pseudo barra de rolagem no topo */
.containerClients .cardClients::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px; /* Altura da pseudo barra */
  background: rgba(0, 0, 0, 0.1); /* Cor de fundo da barra */
  z-index: 2; /* Colocar acima do conteúdo */
  display: none; /* Inicialmente invisível */
}

/* Barra de rolagem normal no fundo */
.containerClients .cardClients::-webkit-scrollbar {
  height: 8px; /* Tamanho da barra de rolagem */
}

.containerClients .cardClients::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}



.containerClients .cardClients .header,
.containerClients .cardClients .containerItens .client {
  display: flex;
  width: 100%;
}

.containerClients .cardClients .header div,
.containerClients .cardClients .containerItens .client div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  text-align: center;
  flex: none;
  width: 200px;
}


.containerClients .cardClients .containerItens .client div {
  padding:  5px;
}

.containerClients .cardClients .header div {
  font-size: 24px;
  font-weight: 700;
}

.containerClients .cardClients .header .containerIconFilter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.containerClients .cardClients .header .containerIconFilter img {
  cursor: pointer;
  width: 25px;
}

.containerClients .cardClients .header .containerIconFilter button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #a62527;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  border-radius: 2px;
  font-size: 18px;
}

.containerClients .cardClients .containerItens .client div {
  font-size: 21px;
  font-weight: 400;
}

.containerClients .cardClients .containerItens .buttonMoreCheck {
  width: 100%;
  height: 50px;
  border-radius: 16px;
  background-color: #77E191;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
}

.containerPartners .cardClients .containerItens .containerName {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px !important;
}

.containerPartners .cardClients .containerItens .containerName:hover {
  border: 1px solid #102649;
  border-radius: 15px;
  opacity: 0.6;
  padding: 9px !important;  
  
}

.containerPartners .cardClients .containerItens .containerName .userIMage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;  
}


.containerClients .containerPagination {
  display: flex;
  justify-content: space-between;
  font-size: 21px;
  font-weight: 400;
  margin-top: 20px;
}

.containerClients .containerPagination .pagination {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 400px;
  justify-content: space-between;
}

.containerClients .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #102649;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.containerClients .pagination .containerButton {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 700;
  font-family: "Outfit", sans-serif;
  color: #102649;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 12px;
}

.containerClients .pagination .containerButton:hover {
  opacity: 55%;
}

.containerClients .pagination .containerButton .left {
  transform: rotate(-180deg);
}

.containerClients .containerPagination .totalPage {
  display: flex;
  gap: 10px;
}

.containerClients .containerPagination .totalPage .text {
  font-size: 21px;
  font-weight: 700;
}

@media only screen and (max-width: 1920px) {
  .containerClients {
    padding: 20px;
  }

  .containerClients .containerInputsClients {
    min-height: 45px;
  }

  .containerClients .containerInputsClients .inputSearch {
    height: 35px;
    font-size: 16px;
  }


  .containerClients .cardClients .header div {
    font-size: 21px;
  }


  .containerClients .cardClients .header .containerIconFilter img {
    width: 20px;
  }

  .containerClients .cardClients .header .containerIconFilter button {
    width: 20px;
    height: 20px;
    font-size: 16px;
  }

  .containerClients .cardClients .containerItens .client div {
    font-size: 18px;
  }

  .containerClients .cardClients .containerItens .buttonMoreCheck {
    width: 100%;
    height: 40px;
    font-size: 18px;
  }

  .containerClients .cardClients .containerItens .containerName .userIMage {
    width: 30px;
    height: 30px;
  }

  .containerClients .containerPagination {
    font-size: 16px;
  }

  .containerClients .containerPagination .pagination {
    gap: 15px;
  }

  .containerClients .pagination .containerButton {
    font-size: 16px;
  }


  .containerClients .containerPagination .totalPage .text {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1437px) {
  .containerClients {
    padding: 5px 20px;
  }

  .containerClients .cardClients .header div,
  .containerClients .cardClients .containerItens .client div {
    flex: none;
    width: 200px;
  }

  .containerClients .containerInputsClients {
    min-height: 45px;
  }

  .containerClients .containerInputsClients .inputSearch {
    height: 35px;
    font-size: 14px;
  }


  .containerClients .cardClients .header div {
    font-size: 18px;
  }


  .containerClients .cardClients .containerItens .client div {
    font-size: 16px;
  }

  .containerClients .cardClients .containerItens .buttonMoreCheck {
    height: 35px;
    font-size: 16px;
  }
}


@media only screen and (max-width: 720px) {

  .containerClients .containerPagination {
    font-size: 14px;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
  }


  .containerClients .pagination .containerButton {
    font-size: 14px;
  }

  .containerClients .containerPagination .pagination {
    gap: 10px;
    width: 90vw;
  }

  .containerClients .containerPagination .totalPage .text {
    font-size: 16px;
  }

  .containerClients .containerPagination .totalPage {
    justify-content: flex-end;
  }

  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerProfile {
  display: flex;
  gap: 30px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  padding: 35px;
  margin-top: 70px;
  flex: 1;
}

.containerProfile .headerProfile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 350px;
}

.containerProfile .cardProfile {
  background-color: #FFFFFF;
  padding: 35px;
  border-radius: 20px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.containerProfile .containerText {
  display: flex;
  flex-wrap: nowrap;
}

.containerProfile .containerText strong {
  flex-shrink: 0;
  margin-right: 5px;
}

.containerProfile .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.containerProfile .cardProfile .title {
  font-weight: 700;
  font-size: 28px;
  align-self: center;
  margin-bottom: 15px;
  margin-top: 60px;
}

.containerProfile .cardProfile .containerImg {
  position: absolute;
  top: -80px;
  left: 50%;
  transform: translateX(-50%);
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerProfile .cardProfile .containerImg .iconUser {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.containerProfile .cardProfile .containerImg .editIcon {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
  
}