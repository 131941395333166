.containerStep4 {
  padding: 40px;
  background-color: #FFFFFF;
  border-radius: 20px 0px 20px 20px !important;
  gap: 45px !important;
  display: flex;
  flex-direction: column;
}

.containerStep4 .containerInputs {
  display: flex;
  flex: 1;
  gap: 30px;
  position: relative;
}

.containerStep4 .containerInputs .card {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  width: 48%;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 21px;
  font-weight: 400;
  user-select: none;
  position: relative;
  margin-bottom: 10px;
}

.checkbox-container input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.checkmarkBackground {
  height: 30px;
  width: 30px;
  background-color: #F3F5F8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkmark {
  height: 16px;
  width: 16px;
  background-color: #F3F5F8;
}

.checkbox-container input:checked~.checkmark {
  background-color: #77E191;
}

.checkmark:after {
  content: "";
  display: none;
}

.checkbox-container input:checked~.checkmark:after {
  display: block;
}

.inpurt .textTOne {
  font-size: 21px !important;
  font-weight: 400 !important;
  cursor: pointer !important;
  font-family: "Outfit", sans-serif !important;
  color: #102649 !important;
}

@media only screen and (max-width: 1153px) {
  
  .containerStep4 .containerInputs {
    flex-direction: column;
  }

  .containerStep4 .containerInputs .card {
    width: 100%;
  }
  
  .checkbox-container {
    font-size: 16px;
  }
  
  .checkmarkBackground {
    height: 30px;
    width: 30px;
  }
  
  .checkmark {
    height: 16px;
    width: 16px;
  }
}


@media only screen and (max-width: 650px) {
  .containerStep4 {
    border-radius: 20px !important;
    gap: 20px !important;
  }
}
