@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.swiper-button-next,
.swiper-button-prev {
  color: #539d65;
}

.swiper-pagination-bullet {
  background-color: #539d65;
}

.swiper-pagination-bullet-active {
  background-color: #539d65;
}

.swiperStartAlign {
  align-items: start;
}

.containerCRms {
  padding: 40px 50px;
  background-color: #FFFFFF;
  margin: 60px;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerCRms .buttonMoreLead {
  border-radius: 16px;
  padding: 10px 15px;
  background-color: #77E191;
  cursor: pointer;
  align-self: flex-end;
  font-family: Outfit;
  font-size: 21px;
  font-weight: 700;
  color: #102649;
  border: none;
}

.titleCrm {
  font-weight: 700;
  font-size: 21px;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

.titleCrm2 {
  font-weight: 500;
  font-size: 1.25rem;
  color: #fafafa;
  align-self: flex-start;
  margin: 10px;
}

.containerAllINputsCrm {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  gap: 0px;
}

.containerInputCrm {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  height: 140px;
  line-height: 20px;
}

.containerInputCrm .title {
  font-size: 17px;
  color: #102649;
}

.containerInputCrm .subTitleInput {
  font-size: 15px;
  font-weight: 400;
  color: #8291A9;
}

.containerInputCrm .inputCRm{
  width: 100%;
  height: 60px;
  border-radius: 20px;
  background-color: #F3F5F8;
  border: none;
  padding: 10px;
  font-size: 21px;
  font-weight: 400;
  margin-top: 5px;
}

.containerButonsCrm {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 10px;
}

.containerButonsCrm .buttonSend {
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #77E191;
  cursor: pointer;
  align-self: flex-end;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 700;
  color: #102649;
  border: none;
}

.containerButonsCrm .buttonMOre {
  border-radius: 10px;
  padding: 5px 10px;
  background-color: #fafafa;
  cursor: pointer;
  align-self: flex-end;
  font-family: Outfit;
  font-size: 16px;
  font-weight: 700;
  color: #102649;
  border: 1px solid #77E191;
}

@media only screen and (max-width: 700px) {
  .containerCRms {
    padding: 0px;
    background-color: #FFFFFF;
    margin: 0px;
  }
}