.containerStep1 {
  padding: 40px;
  background-color: #FFFFFF;
  border-radius: 0px 20px 20px 20px !important;
  gap: 45px;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 650px) {
  .containerStep1 {
    border-radius: 20px !important;
    gap: 20px !important;
  }
}

.containerInput textarea.inputStyle {
  min-height: 120px;
  padding: 15px;
  line-height: 1.5;
  font-family: "Outfit", sans-serif;
  resize: vertical;
}

.containerInput textarea.inputStyle:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.containerInputs {
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
  position: relative;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}

.rightColumn {
  height: 100%;
  width: 50%;
}

.instructionsInput {
  height: 100%;
}

.instructionsInput textarea.inputStyle {
  height: calc(100% - 80px); /* Ajuste conforme necessário para considerar o título e subtítulo */
  min-height: 300px;
  resize: none;
}

/* Para mobile, volta para 1 coluna */
@media (max-width: 768px) {
  .containerInputs {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .instructionsInput textarea.inputStyle {
    height: 200px;
  }
}
