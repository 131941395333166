@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerFinancePartner {
  display: flex;
  gap: 20px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  padding: 35px;
  margin-top: 70px;
  flex: 1;
}

.containerFinancePartner .containerCredCards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 10px;
}

.containerFinancePartner .containerCredCards .cardCredit {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.containerFinancePartner .containerCredCards .cardCredit .text {
  font-size: 21px;
  font-weight: 700;
}

.containerFinancePartner .containerCredCards .cardCredit .subtitle {
  font-size: 21px;
  font-weight: 400;
}


.containerFinancePartner .headerFinancePartner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
}

.containerFinancePartner .cardFinancePartner {
  background-color: #FFFFFF;
  padding: 35px;
  border-radius: 20px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.containerFinancePartner .containerText {
  display: flex;
  flex-wrap: nowrap;
}

.containerFinancePartner .containerText strong {
  flex-shrink: 0;
  margin-right: 5px;
}

.containerFinancePartner .containerText span {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.containerFinancePartner .cardFinancePartner .title {
  font-weight: 700;
  font-size: 28px;
  align-self: center;
  margin-bottom: 15px;
}

.containerFinancePartner .cardScore {
  background-color: #FFFFFF;
  padding: 35px;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

.containerFinancePartner .cardScore img {
  width: 41px;
  height: 41px;
}

.containerFinancePartner .cardScore .containerText {
  display: flex;
  flex-direction: column;
}


.containerFinancePartner .cardScore .containerText .title {
  font-weight: 400;
  font-size: 21px;
}


.containerFinancePartner .cardScore .containerText .subtitle {
  font-weight: 700;
  font-size: 24px;
}

.containerSumary {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 3;
}

.containerSumary .containerBox {
  background-color: #FFFFFF;
  padding: 35px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}


.containerSumary .containerBox .title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 24px;
}

.containerSumary .containerBox img {
  width: 17px;
  height: 17px;
}

.containerSumary .containerBox .text {
  font-weight: 400;
  font-size: 18px;
}

.containerSumary .containerBox .containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.containerSumary .containerBox .containerHeader .containerIcons {
  display: flex;
  gap: 10px;
}

.containerSumary .containerBox .containerHeader .containerIconFilter img {
  cursor: pointer;
  width: 25px;
}

.containerSumary .containerBox .containerHeader .containerIconFilter button {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #a62527;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 700;
  border-radius: 2px;
  font-size: 18px;
}


.containerSumary .containerBox .containerHeader .containerIconFilter img {
  width: 20px;
  height: 20px;
}

.containerSumary .containerBox .containerHeader .containerIconFilter button {
  width: 20px;
  height: 20px;
  font-size: 16px;
}

.containerSumary .containerBox .inputSearch {
  width: 100%;
  height: 35px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  font-size: 18px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #dddddd;
}


.containerFinancePartner .buttonNextCRedCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

.containerFinancePartner .buttonNextCRedCard .imgBUtonNex {
  width: 10px;
  height: 12px;
}

.containerFinancePartner .cardScore .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20px;
  justify-content: end;
  margin-top: 10px;
}

.containerFinancePartner .cardScore .fill {
  background-color: #ECEFF4;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  height: 100%;
}

.containerFinancePartner .cardScore .leads {
  background-color: #77E191;
  display: flex;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #102649;
  height: 100%;
}


@media only screen and (max-width: 1920px) {

  .containerFinancePartner {
    padding: 20px;
  }

  .containerFinancePartner .cardFinancePartner {

    padding: 20px;
    font-size: 18px;
  }

  .containerFinancePartner .cardFinancePartner .title {
    font-size: 21px;
  }

  .containerFinancePartner .cardScore .containerText .title {
    font-size: 18px;
  }

  .containerFinancePartner .containerCredCards .cardCredit .text {
    font-size: 18px;
    font-weight: 700;
  }
  
  .containerFinancePartner .containerCredCards .cardCredit .subtitle {
    font-size: 18px;
    font-weight: 400;
  }


  .containerFinancePartner .cardScore .containerText .subtitle {
    font-size: 18px;
  }

  .containerSumary .containerBox {
    padding: 20px;
  }


  .containerSumary .containerBox .title {
    font-size: 20px;
  }


  .containerSumary .containerBox .text {
    font-size: 16px;
  }

  .containerSumary .containerBox .containerHeader .containerIconFilter button {
    font-size: 16px;
  }

  .containerSumary .containerBox .containerHeader .containerIconFilter button {
    font-size: 14px;
  }

  .containerSumary .containerBox .inputSearch {
    font-size: 16px;
  }


  .containerFinancePartner .buttonNextCRedCard {
    padding: 10px 17px;
    font-size: 18px;
    gap: 10px;
  }
}

@media only screen and (max-width: 1450px) {
  .containerFinancePartner {
    flex-direction: column;
  }

  .containerFinancePartner .headerFinancePartner {
    max-width: 100%;
  }
}

@media only screen and (max-width: 1094px) {

  .containerFinancePartner .cardFinancePartner {
    font-size: 16px;
  }

  .containerFinancePartner .cardFinancePartner .title {
    font-size: 18px;
  }

  .containerFinancePartner .cardScore .containerText .title {
    font-size: 16px;
  }


  .containerFinancePartner .containerCredCards .cardCredit .text {
    font-size: 16px;
    font-weight: 700;
  }
  
  .containerFinancePartner .containerCredCards .cardCredit .subtitle {
    font-size: 16px;
    font-weight: 400;
  }


  .containerFinancePartner .cardScore .containerText .subtitle {
    font-size: 16px;
  }

  .containerSumary .containerBox {
    padding: 18px;
  }


  .containerSumary .containerBox .title {
    font-size: 18px;
  }


  .containerSumary .containerBox .text {
    font-size: 14px;
  }

  .containerSumary .containerBox .containerHeader .containerIconFilter button {
    font-size: 14px;
  }

  .containerSumary .containerBox .containerHeader .containerIconFilter button {
    font-size: 14px;
  }

  .containerSumary .containerBox .inputSearch {
    font-size: 14px;
  }


  .containerFinancePartner .buttonNextCRedCard {
    padding: 10px 17px;
    font-size: 16px;
    gap: 10px;
    align-self: flex-start !important;
  }

}

@media only screen and (max-width: 600px) {

  .containerFinancePartner .containerCredCards {
    display: flex;
    flex-direction: column;
  }

  .containerFinancePartner .containerCredCards .cardCredit {
    flex-direction: row;

  }
}