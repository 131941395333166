@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerDashboard {
  padding: 30px;
  display: flex;
  gap: 20px;
  /* overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; */
}

.containerDashboard .containerLeft,
.containerDashboard .containerRigh {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerDashboard .containerRigh {
  flex: 1.5;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerDashboard .containerLeft {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.containerDashboard .line {
  height: 100%;
  width: 2px;
  background-color: #DEE4EF;
}

.containerDashboard .containerHeader {
  display: flex;
  flex-direction: column;
}

.containerDashboard .containerHeader .title,
.containerDashboard .containerHeader .text {
  font-family: "Outfit", sans-serif;
  text-align: left;
  color: #102649;
}

.containerDashboard .containerHeader .title {
  font-weight: 700;
  font-size: 28px;
}

.containerDashboard .containerHeader .text {
  font-weight: 400;
  font-size: 24px;
}

.containerDashboard .containerInfosLeadsSends {
  display: flex;
  justify-content: space-between;
  font-family: "Outfit", sans-serif;
  text-align: left;
  color: #102649;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  gap: 20px;
}


.containerDashboard .containerInfosLeadsSends .containerInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 30px 25px;
  gap: 10px;
  background: #FFFFFF;
  border-radius: 20px;
}

.containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
  font-weight: 700;
  font-size: 62px;
  gap: 10px;
  display: flex;
  align-items: center;
}

.containerDashboard .containerTokens {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  gap: 15px;
  background: #FFFFFF;
  border-radius: 20px;
}

.linkInvite {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  gap: 15px;
  background: #FFFFFF;
  border-radius: 20px;
  margin-top: 20px;
}

.containerDashboard .containerTokens .containerHeaderTokens {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-family: "Outfit", sans-serif;
  text-align: left;
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerDashboard .containerTokens .bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 20px;
  justify-content: end;
}

.containerDashboard .containerTokens .fill {
  background-color: #ECEFF4;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-end;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  height: 100%;
}

.containerDashboard .containerTokens .leads {
  background-color: #77E191;
  display: flex;
  color: white;
  font-weight: bold;
  border-radius: 20px;
  font-family: "Outfit", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #102649;
  height: 100%;
}

.containerAlertGoogle {
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: #FFFFFF;
  border: 2px solid #CE3C3C;
  padding: 15px 10px;
  border-radius: 20px;
  gap: 20px;
}

.containerAlertGoogle .containerText {
  display: flex;
  align-items: center;
  gap: 20px;
}

.containerAlertGoogle .text {
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerAlertGoogle .button {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  font-weight: 700;
  color: #102649;
  background: #77E191;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 20px;
}

.containerHeader .link {
  width: 100%;
  height: 60px;
  border-radius: 20px;
  background-color: #dde9fa;
  border: none;
  padding: 0px;
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
  resize: none;
  font-family: "Outfit", sans-serif;
  color: #102649;
  text-align: center;
}

@media only screen and (max-width: 1920px) {
  .containerDashboard .containerHeader .title {
    font-size: 25px;
  }

  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends {
    font-size: 18px;
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
    font-size: 55px;
  }

  .containerDashboard .containerTokens .containerHeaderTokens {
    font-size: 18px;
  }

  .containerDashboard .containerTokens .leads {
    font-size: 13px;
  }
  
  .containerAlertGoogle .text {
    font-size: 18px;
  }
  
  .containerAlertGoogle .button {
    font-size: 18px;
    padding: 10px 15px;
    border-radius: 15px;
  }
}


@media only screen and (max-width: 1319px) {
  .containerDashboard {
    flex-direction: column;
  }

  .containerDashboard .line {
    display: none;
  }
}

@media only screen and (max-width: 883px) {
  .containerDashboard {
    padding: 35px;
    display: flex;
    gap: 20px;
    /* height: 100%;
    overflow: none; */
  }

  .containerDashboard .containerHeader .title {
    font-size: 18px;
  }

  .containerDashboard .containerHeader .text,
  .containerDashboard .containerInfosLeadsSends {
    font-size: 12px;
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo .textImg {
    font-size: 42px;
  }

  .containerDashboard .containerTokens .containerHeaderTokens {
    font-size: 16px;
  }

  .containerDashboard .containerTokens .leads {
    font-size: 13px;
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo img {
    width: 32px;
  }

  .containerDashboard .containerInfosLeadsSends .containerInfo {
    padding: 25px 15px;
    gap: 10px;
  }


  .containerDashboard .containerTokens {
    padding: 25px 15px;
    gap: 15px;
  }

  .containerDashboard {
    padding: 25px;
    gap: 10px;
  }

  .containerDashboard .containerLeft,
  .containerDashboard .containerRigh {
    gap: 10px;
  }

  .containerAlertGoogle .text {
    font-size: 14px;
  }
  
  .containerAlertGoogle .button {
    font-size: 14px;
    padding: 10px 15px;
    border-radius: 10px;
    gap: 5px;
  }

  .containerAlertGoogle .button img {
    width: 8px;
  }

  .containerHeader .link {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
}