.containerStep5 {
  padding: 30px;
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  /* gap: 30px; */
  display: flex;
  flex-direction: column;
}

@keyframes typing {
  0% {
    content: '';
  }
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  80% {
    content: '....';
  }
  100% {
    content: '.....';
  }
}

.typing::after {
  content: '';
  animation: typing 1s steps(6, end) infinite;
  font-size: 24px;
  font-weight: bold;
}

.chat-container {
  display: flex;
  padding: 20px;
  font-family: Arial, sans-serif;
  gap: 30px;
}

.chat-container .tokensContainer {
  width: 400px;
}

.tokens-used {
  background-color: #F4F6F8;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tokens-used .title {
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.tokens-used .subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #102649;
}

.plans {
  display: flex;
  flex-direction: column;
}

.plans .containerText {
  display: flex;
  gap: 10px;
  height: 100%;
}

.plans .text {
  font-size: 24px;
  font-weight: 700;
  color: #102649;
}

.plans .cicle {
  color: #77E191;
  font-weight: 700;
}

.plans .subText {
  font-size: 21px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
  margin-bottom: 10px;
}


.chat-box {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0px 20px;
  position: relative;
  background-image: url(../../../../../assets/bg-barra.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 55vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.chat-box::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.containerIputMenssage {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.containerIputMenssage .inputMenssage {
  width: 90%;
  height: 60px;
  border-radius: 100px;
  box-shadow: 2px 2px 8px 0px #00000026;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerIputMenssage .inputMenssage:focus {
  outline: none;
  box-shadow: 2px 2px 8px 0px #00000026;
}

.containerIputMenssage button {
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #77E191;
  border: none;
  cursor: pointer;
}

.containerGroupMensagens {
  display: flex;
  flex-direction: column;
  height: 43vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.containerGroupMensagens::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.containerGroupMensagens .dateHeader {
  font-size: 16px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #70829E;
  background-color: #D1E8F4;
  height: 29px;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  border-radius: 10px;
  padding: 5px 10px;
}

.containerGroupMensagens .containerBoxMenssagens {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 25px;
}

.message {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.received {
  border-radius: 0px 20px 20px 20px;
  box-shadow: 2px 2px 8px 0px #0f0b0b26;
  background-color: #FFFFFF;
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
  color: #102649;
  min-width: 100px;
  padding: 25px 15px;
  position: relative;
  align-self: flex-start;
  margin-left: 20px;
}

.received:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 20px solid #FFFFFF;
  border-bottom: 2px solid transparent;
  border-left: 5px solid transparent;
  border-right: 20px solid #FFFFFF;
  left: -20px;
  top: 0px;
}

.sent {
  border-radius: 20px 0px 20px 20px;
  box-shadow: 2px 2px 8px 0px #00000026;
  background-color: #E8EACF;
  font-family: "Outfit", sans-serif;
  font-size: 21px;
  font-weight: 400;
  text-align: left;
  color: #102649;
  min-width: 100px;
  padding: 15px 25px;
  margin-right: 20px;
  position: relative;
  align-self: flex-end;
}

.sent:after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-top: 20px solid #E8EACF;
  border-bottom: 2px solid transparent;
  border-left: 20px solid #E8EACF;
  border-right: 5px solid transparent;
  right: -20px;
  top: 0px;
}

.time {
  font-size: 12px;
  color: #999;
  align-self: flex-end;
}

.chat-box input {
  width: calc(100% - 20px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
}

@media only screen and (max-width: 1153px) {
  .containerStep5 {
    padding: 15px;
  }

  .chat-box {
    width: 100%;
    padding: 10px;
  }

  .chat-container {
    flex-direction: column;
  }

  .chat-container .tokensContainer {
    width: 100%;
    order: 2;
  }

  .tokens-used .title {
    font-size: 17px;
  }

  .tokens-used .subtitle {
    font-size: 20px;
  }

  .tokens-used img {
    width: 40px;
  }


  .plans .text {
    font-size: 18px;
  }

  .plans .subText {
    font-size: 16px;
  }


  .containerIputMenssage {
    gap: 10px;
  }


  .containerIputMenssage .inputMenssage {
    height: 40px;
    font-size: 16px;
  }

  .containerIputMenssage button {
    width: 40px;
    height: 40px;
  }


  .containerGroupMensagens .dateHeader {
    font-size: 14px;
    height: 29px;
    margin: 20px;
  }

  .containerGroupMensagens .containerBoxMenssagens {
    gap: 20px;
  }


  .received {
    font-size: 18px;
    min-width: 100px;
    padding: 20px 10px;
    margin-left: 5px;
  }

  .received:after {
    border-top: 10px solid #FFFFFF;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    left: -5px;
    top: 0px;
  }

  .sent {
    font-size: 18px;
    min-width: 100px;
    padding: 10px 20px;
    margin-right: 5px;
  }

  .sent:after {
    border-top: 10px solid #E8EACF;
    border-bottom: 5px solid transparent;
    border-left: 10px solid #E8EACF;
    border-right: 10px solid transparent;
    right: -10px;
    top: 0px;
  }

  .time {
    font-size: 10px;
    color: #999;
    align-self: flex-end;
  }

}