@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

.containerTokens {
  display: flex;
  gap: 30px;
  font-family: "Outfit", sans-serif;
  color: #102649;
  padding: 35px;
  margin-top: 70px;
  flex: 1;
}

.containerTokens .headerTokens {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 400px;
}

.containerTokens .cardTokens {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 20px;
  font-size: 21px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.containerTokens .containerText {
  display: flex;
  flex-wrap: nowrap;
}

.containerTokens .title {
  font-weight: 700;
  font-size: 28px;
  align-self: center;
  margin-bottom: 15px;
  margin-top: 60px;
}

.containerTokens .containerSlider {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 30px;
}

.containerTokens .containerSlider .cardSlider {
  background-color: #FFFFFF;
  padding: 30px;
  border-radius: 20px;
}

.containerTokens .containerSlider .titleHea {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 28px;
  font-weight: 700;
}

.containerTokens .containerSlider .subTitle {
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerTokens .tokens-used {
  background-color: #F4F6F8;
  padding: 20px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.containerTokens .tokens-used .title {
  font-size: 21px;
  font-weight: 400;
  color: #102649;
}

.containerTokens .tokens-used .subtitle {
  font-size: 24px;
  font-weight: 700;
  color: #102649;
}

.containerTokens .plans {
  display: flex;
  flex-direction: column;
}

.containerTokens .containerText {
  display: flex;
  gap: 10px;
  height: 100%;
}

.containerTokens .text {
  font-size: 21px;
  font-weight: 700;
  color: #102649;
}

.containerTokens .cicle {
  color: #77E191;
  font-weight: 700;
}

.containerTokens .subText {
  font-size: 21px;
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
  margin-bottom: 10px;
}

.containerTokens .textFont {
  font-weight: 400;
  font-family: "Outfit", sans-serif;
  color: #102649;
}

.containerSlider .buttonSlide {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 20px;
  background-color: #77E191;
  font-size: 21px;
  font-weight: 700;
  border-radius: 16px;
  gap: 10px;
  align-self: flex-end;
  cursor: pointer;
  border: none;
  margin-top: 15px;
}

.containerSlider .containerResume {
  display: flex;
  gap: 30px;
  margin-top: 20px;
  justify-content: space-between;
}


@media only screen and (max-width: 1920px) {
  .containerTokens {
    gap: 25px;
    padding: 25px;
  }

  .containerTokens .cardTokens {
    background-color: #FFFFFF;
    padding: 25px;
    font-size: 19px;
  }

  .containerTokens .title {
    font-size: 26px;
  }

  .containerTokens .containerSlider .titleHea {
    font-size: 26px;
  }

  .containerTokens .containerSlider .subTitle {
    font-size: 19px;
  }


  .containerTokens .tokens-used .title {
    font-size: 17px;
  }

  .containerTokens .tokens-used .subtitle {
    font-size: 20px;
  }

  .containerTokens .tokens-used img {
    width: 40px;
  }

  .containerSlider.plans .text {
    font-size: 18px;
  }

  .containerTokens .subText {
    font-size: 16px;
  }

  .containerSlider .buttonSlide {
    padding: 10px 17px;
    font-size: 18px;
    gap: 10px;
  }
}

@media only screen and (max-width: 1094px) {
  .containerTokens {
    flex-direction: column;
  }

  .containerTokens .containerSlider .subTitle {
    font-size: 18px;
  }

  .containerSlider .buttonSlide {
    padding: 10px 17px;
    font-size: 16px;
    gap: 10px;
    align-self: flex-start !important;
  }

  .containerTokens .containerSlider .titleHea {
    font-size: 20px;
  }

}